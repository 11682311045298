































import Vue from 'vue'
import AppButton from '@/components/ui/AppButton.vue'

export default Vue.extend({
    components: { AppButton }
})
