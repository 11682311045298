var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "viewBox": "0 0 20 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, _vm.$listeners), [_c('path', {
    attrs: {
      "d": "M19 2.75C19 1.788 18.19 1 17.2 1H2.8C1.81 1 1 1.788 1 2.75m18 0v10.5c0 .963-.81 1.75-1.8 1.75H2.8c-.99 0-1.8-.787-1.8-1.75V2.75m18 0l-9 6.125L1 2.75",
      "stroke": "inherit",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }