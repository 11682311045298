var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "viewBox": "0 0 18 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, _vm.$listeners), [_c('path', {
    attrs: {
      "d": "M16.372 14.821a7.958 7.958 0 00-1.715-2.542 7.992 7.992 0 00-2.542-1.715c-.009-.005-.017-.007-.026-.01A5.276 5.276 0 009 1a5.276 5.276 0 00-3.088 9.554c-.009.005-.017.007-.026.01a7.922 7.922 0 00-2.542 1.716A7.99 7.99 0 001 17.76a.17.17 0 00.17.175h1.277a.17.17 0 00.17-.166 6.343 6.343 0 011.868-4.347A6.34 6.34 0 019 11.553a6.34 6.34 0 014.515 1.87 6.343 6.343 0 011.868 4.347.169.169 0 00.17.166h1.277a.171.171 0 00.17-.174 7.928 7.928 0 00-.628-2.94zM9 9.936c-.977 0-1.896-.38-2.587-1.072A3.636 3.636 0 015.34 6.277c0-.977.381-1.896 1.073-2.588A3.636 3.636 0 019 2.617c.977 0 1.896.38 2.587 1.072a3.636 3.636 0 011.073 2.588c0 .976-.381 1.895-1.073 2.587A3.636 3.636 0 019 9.936z"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }