export interface CatalogItem {
    name: string,
    alias?: string,
    expanded: Boolean,
    nested?: CatalogItem[],
    url?: string,
    icon?: string,
    badgeColor?: string
}

export const isCatalogItem = (item: any): item is CatalogItem => (
    item
    && typeof item.name === 'string'
    && typeof item.alias === 'string'
    && typeof item.expanded === 'boolean'
    && (!item.nested || item.nested.every(isCatalogItem))
    && (!item.url || typeof item.url === 'string')
    && (!item.icon || typeof item.icon === 'string')
    && (!item.badge || typeof item.badge === 'string')
)
