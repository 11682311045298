var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: {
      'app-aside': true,
      'app-aside--hide-background': _vm.hideBackground,
      'app-aside--open': _vm.isShowing
    },
    on: {
      "click": function ($event) {
        if ($event.target !== $event.currentTarget) { return null; }
        return _vm.$emit('on-close');
      }
    }
  }, [_c('base-aside', _vm._b({
    ref: "$aside",
    class: ['app-aside__core', ("app-aside__core--" + (_vm.placement))]
  }, 'base-aside', _vm.$props, false), [_vm._t("default")], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }