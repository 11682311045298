














import { defineComponent, ref, PropType } from '@nuxtjs/composition-api'

import AppModal from '@/components/ui/AppModal.vue'
import SignUp, { SignUpTypes, PartialState } from '@/components/ui/TheSignUp/index.vue'

export default defineComponent({
    components: {
        SignUp,
        AppModal
    },
    props: {
        type: {
            type: String as PropType<SignUpTypes>,
            default: 'customer'
        }
    },
    setup: () => {
        const initialState = ref<PartialState>({})

        const $modal = ref<InstanceType<typeof AppModal> | null>(null)
        const open = (state?: PartialState) => {
            $modal.value?.open()

            if (state) {
                initialState.value = state
            }
        }

        return {
            $modal,
            initialState,
            open
        }
    }
})
