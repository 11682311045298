















import { PropType, defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
    props: {
        errors: {
            type: Array as PropType<string[]>,
            default: () => []
        }
    }
})
