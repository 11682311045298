var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, _vm.$listeners), [_c('path', {
    attrs: {
      "d": "M17.071 2.929c3.905 3.905 3.905 10.237 0 14.142-3.905 3.905-10.237 3.905-14.142 0-3.905-3.905-3.905-10.237 0-14.142 3.905-3.905 10.237-3.905 14.142 0zm-12.02 12.02A7 7 0 1014.95 5.05a7 7 0 00-9.9 9.9z",
      "fill-opacity": ".4"
    }
  }), _c('path', {
    attrs: {
      "d": "M16.01 3.99c.586-.586 1.547-.592 2.038.075a10.002 10.002 0 010 11.87c-.491.667-1.452.661-2.038.075-.585-.585-.569-1.53-.12-2.226a6.999 6.999 0 000-7.568c-.449-.697-.465-1.64.12-2.226z"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }