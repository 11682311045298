var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, _vm.$listeners), [_c('path', {
    attrs: {
      "d": "M3.6 14.16S6.655 9.12 12 9.12c5.346 0 8.4 5.04 8.4 5.04S17.346 19.2 12 19.2c-5.345 0-8.4-5.04-8.4-5.04z",
      "stroke": "inherit",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M12 6.6V5.4M6.24 7.92l-.6-1.04M17.76 7.92l.6-1.04",
      "stroke": "inherit",
      "stroke-width": "2",
      "stroke-linecap": "round"
    }
  }), _c('circle', {
    attrs: {
      "cx": "12",
      "cy": "14.1",
      "r": "2.4",
      "stroke": "inherit",
      "stroke-width": "1.5"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }