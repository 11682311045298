

























import { defineComponent, ref } from '@nuxtjs/composition-api'

export default defineComponent({
    props: {
        initialOpen: {
            type: Boolean,
            default: false
        }
    },
    setup: (props, { emit }) => {
        const isOpen = ref(props.initialOpen)
        const close = () => {
            isOpen.value = false
            emit('on-close')
        }
        const open = () => {
            isOpen.value = true
            emit('on-open')
        }
        const toggle = () => {
            if (isOpen.value) {
                close()
            } else {
                open()
            }

            emit('on-toggle')
        }

        return {
            isOpen,
            toggle,
            close,
            open
        }
    }
})
