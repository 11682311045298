var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "app-avatar"
  }, [_c('div', {
    ref: "$wrapper",
    staticClass: "app-avatar__content-wrapper",
    style: _vm.initialsStyle
  }, [_vm.image ? _c('img', {
    staticClass: "app-avatar__image",
    attrs: {
      "src": _vm.image
    }
  }) : _c('div', {
    staticClass: "app-avatar__initials"
  }, [_vm._v("\n            " + _vm._s(_vm.initials) + "\n        ")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }