import { render, staticRenderFns } from "./-Popup.vue?vue&type=template&id=54aae408&scoped=true&"
import script from "./-Popup.vue?vue&type=script&lang=ts&"
export * from "./-Popup.vue?vue&type=script&lang=ts&"
import style0 from "./-Popup.vue?vue&type=style&index=0&id=54aae408&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54aae408",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppModal: require('/var/www/app/src/components/ui/AppModal.vue').default})
