var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "catalog-item-skeleton"
  }, [_c('div', {
    staticClass: "catalog-item-skeleton-icon"
  }), _vm._v(" "), _c('div', {
    staticClass: "catalog-item-skeleton-name"
  })]);
}]

export { render, staticRenderFns }