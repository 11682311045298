import toCamelCase from 'lodash/camelCase'
import { Auth } from '@nuxtjs/auth-next'
import { OpenAPIClientAxios, Document as OpenAPI3Document } from '@fedorif/openapi-client-axios-custom'
import clientAuthDefinition from '@/swagger/auth.json'
import { Client as ClientAuth } from '@/types/client.auth'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Plugin } from '@nuxt/types'

declare module '@nuxt/types' {
    interface NuxtAppOptions {
        $apiAuth: ClientAuth
        $auth: Auth
    }

    interface Context {
        $apiAuth: ClientAuth
        $auth: Auth
    }
}

const AxiosClientAuth: Plugin = async ({ $axios }, inject) => {
    const api = new OpenAPIClientAxios({
        transformOperationName: o => toCamelCase(o),
        definition: clientAuthDefinition as unknown as OpenAPI3Document,
        axiosInstance: $axios
    })

    const client = await api.getClient<ClientAuth>()

    inject('apiAuth', client)
}

export default AxiosClientAuth
