var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['app-widget-skeleton', {
      'app-widget-skeleton--vertical': _vm.vertical,
      'app-widget-skeleton__news': _vm.newsHorisontal,
      'app-widget-skeleton__news--vertical': _vm.newsVertical
    }]
  });
}
var staticRenderFns = []

export { render, staticRenderFns }