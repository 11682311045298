var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "viewBox": "0 0 17 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, _vm.$listeners), [_c('path', {
    attrs: {
      "d": "M8.37 20c1.1 0 2-.923 2-2.051h-4c0 1.128.9 2.051 2 2.051zm6-6.154V8.718c0-3.149-1.63-5.785-4.5-6.482v-.698C9.87.688 9.2 0 8.37 0s-1.5.687-1.5 1.538v.698c-2.86.697-4.5 3.323-4.5 6.482v5.128l-2 2.051v1.026h16v-1.026l-2-2.05zm-2 1.026h-8V8.718c0-2.544 1.51-4.615 4-4.615s4 2.071 4 4.615v6.154z"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }