var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, _vm.$listeners), [_c('rect', {
    attrs: {
      "width": "24",
      "height": "24",
      "rx": "12",
      "fill": "#333",
      "fill-opacity": ".2"
    }
  }), _c('path', {
    attrs: {
      "d": "M16.447 7.553a.572.572 0 00-.812 0L12 11.188 8.364 7.553a.572.572 0 00-.81 0 .572.572 0 000 .812l3.635 3.634-3.636 3.635a.577.577 0 00.403.98.56.56 0 00.404-.168l3.636-3.635 3.635 3.635c.11.11.26.17.404.17a.553.553 0 00.403-.17.572.572 0 000-.812L12.811 12l3.636-3.634a.572.572 0 000-.812z",
      "fill": "#fff"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }