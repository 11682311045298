









import {
    defineComponent,
    onMounted,
    ref,
    watch
} from '@nuxtjs/composition-api'

import { captchaToken } from '@/composables/sign-up/partner'
import { captchaTokenIam } from '@/composables/sign-up/customer'

export default defineComponent({
    setup: (_props, { emit }) => {
        const captchaLength = ref(0)

        const getCaptcha = () => {
            const recaptchaScript = document.createElement('script')
            recaptchaScript.setAttribute('src', 'https://smartcaptcha.yandexcloud.net/captcha.js')
            document.head.appendChild(recaptchaScript)

            captchaToken.value = ''
            captchaTokenIam.value = ''
        }

        const getToken = () => {
            setTimeout(() => {
                const targetNode = document.getElementsByName('smart-token')[captchaLength.value]
                const config = { attributes: true, childList: true, subtree: true }

                const callback = (mutationList: MutationRecord[]) => {
                    mutationList.forEach((mutation) => {
                        captchaToken.value = mutation.target.value
                        captchaTokenIam.value = mutation.target.value

                        emit('token-received')
                    })
                }

                const observer = new MutationObserver(callback)
                observer.observe(targetNode, config)
            }, 500)
        }

        watch(
            () => captchaToken.value === '',
            () => getToken()
        )

        onMounted(() => {
            setTimeout(() => {
                captchaLength.value = document.getElementsByName('smart-token').length - 1
                getToken()
            }, 500)
        })

        return {
            getCaptcha,
            captchaToken,
            captchaTokenIam
        }
    }
})
