import { ref } from "@nuxtjs/composition-api"
import { Context } from '@nuxt/types'

export const balance = ref({
    current: 0,
    available: 0
})

export const fetchBalances = async (context: unknown) => {
    const { $apiCustomer } = context as Context
    const { data } = await $apiCustomer.executorCabinetsGetTotalBalances()

    balance.value = {
        current: Number(data.current),
        available: Number(data.available)
    }
}
