











import {
    computed,
    defineComponent,
    onMounted,
    useContext,
    watch
} from '@nuxtjs/composition-api'
import { formatNumber, FormatNumberOptions } from '@/composables/utils'
import { balance, fetchBalances } from '@/composables/user-balance'

export default defineComponent({
    props: {
        total: {
            type: Number,
            default: 0
        }
    },
    setup () {
        const context = useContext()
        const { route } = context

        const formatParams: FormatNumberOptions = {
            trimFracts: true,
            groupSeparator: '&nbsp;'
        }

        const formattedAvailable = computed(
            () => formatNumber(balance.value.current, formatParams)
        )

        onMounted(async () => {
            await fetchBalances(context)
        })

        watch(
            () => route.value.params,
            async () => { await fetchBalances(context) }
        )

        return {
            formattedAvailable
        }
    }
})
