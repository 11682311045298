var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header-unread-button', _vm._b({
    staticClass: "header-messages-button"
  }, 'header-unread-button', _vm.$props, false), [_c('nuxt-link', {
    staticClass: "header-messages-button__link",
    attrs: {
      "to": ("/managers-chat/" + (_vm.channelId))
    }
  }, [_c('letter-icon', {
    staticClass: "header-messages-button__icon"
  }), _vm._v(" "), _vm._t("text")], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }