import {
    createSignUp,
    defineLoginType,
    getOriginUrl,
    SignUpConfig,
    SignUpSteps
} from '@/composables/sign-up'
import { Components as Partner } from '@/types/client.partner'
import { unifyPhone } from '@/composables/phone'

export const useAdminPartnerSignUp = createSignUp(
    'admin-partner',
    ({ context, state }) => {
        const { $apiAdmin, redirect } = context

        const config: SignUpConfig = {
            allowedIdentifierTypes: [ 'Email' ],
            buttonsColor: 'green',
            requireActivityType: true
        }

        const defineStep = (humanTask?: Partner.Schemas.PartnerSignupHumanTaskOneOf) => {
            if (humanTask?.partnerDetailsHumanTask) {
                redirect(`/partners/${humanTask.partnerDetailsHumanTask.partnerId}#new-partner`)

                return undefined
            }

            state.step = SignUpSteps.Registration
        }

        const start = async () => {
            const userIdentifierType = defineLoginType(state.login)
            const value = userIdentifierType === 'PhoneNumber'
                ? unifyPhone(state.login)
                : state.login

            const { data } = await $apiAdmin.partnersSignUpSignUpByAdmin(null, {
                activityTypeId: state.activityTypeId,
                transitSource: getOriginUrl(),
                userIdentifier: { userIdentifierType, value }
            })

            defineStep(data.partnerSignupHumanTask)
        }

        const fetchActivityTypes = async () => {
            const { data } = await $apiAdmin.activityTypesGetAll()

            return data.map(({ id, name }) => ({
                value: String(id),
                caption: String(name)
            }))
        }

        return {
            config,
            fetchActivityTypes,
            start
        }
    }
)
