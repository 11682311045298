var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "viewBox": "0 0 25 18",
      "fill": "inherit",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, _vm.$listeners), [_c('path', {
    attrs: {
      "d": "M12.7 8.397a3.694 3.694 0 01-3.698-3.699A3.694 3.694 0 0112.7 1 3.694 3.694 0 0116.4 4.698 3.694 3.694 0 0112.7 8.397zm0-6.61A2.922 2.922 0 009.79 4.698c0 1.6 1.311 2.912 2.911 2.912s2.912-1.312 2.912-2.912c0-1.6-1.312-2.911-2.912-2.911zM20.332 8.711a2.586 2.586 0 01-2.597-2.597 2.586 2.586 0 012.597-2.596 2.586 2.586 0 012.597 2.596 2.586 2.586 0 01-2.597 2.597zm0-4.407c-.997 0-1.81.814-1.81 1.81 0 .997.813 1.81 1.81 1.81.997 0 1.81-.813 1.81-1.81 0-.996-.813-1.81-1.81-1.81zM5.065 8.711a2.586 2.586 0 01-2.597-2.597 2.586 2.586 0 012.597-2.596 2.586 2.586 0 012.597 2.596 2.586 2.586 0 01-2.597 2.597zm0-4.407c-.997 0-1.81.814-1.81 1.81 0 .997.813 1.81 1.81 1.81.997 0 1.81-.813 1.81-1.81 0-.996-.813-1.81-1.81-1.81z",
      "stroke": "inherit",
      "stroke-width": ".6"
    }
  }), _c('path', {
    attrs: {
      "d": "M20.357 14.508c-1.049 0-1.705-.053-2.256-.131l-.314-.053v-.446c0-1.101-.341-2.203-.997-3.2l-.131-.184.105-.21c.708-1.416 2.124-2.334 3.567-2.334 2.177 0 4.066 1.967 4.092 4.328 0 2.23-2.072 2.23-4.066 2.23zm-1.783-.866c.445.053.996.079 1.783.079 2.492 0 3.279-.21 3.279-1.443-.026-1.915-1.548-3.54-3.305-3.54-1.102 0-2.177.655-2.78 1.704.63.997.97 2.098 1.023 3.2zM5.066 14.508c-1.994 0-4.066 0-4.066-2.23C1 9.944 2.862 7.95 5.066 7.95c1.468 0 2.859.918 3.567 2.335l.105.21-.131.183c-.656.997-.997 2.072-.97 3.174v.446l-.316.052c-.55.105-1.206.158-2.255.158zm0-5.77c-1.758 0-3.28 1.652-3.28 3.54 0 1.233.788 1.443 3.28 1.443.787 0 1.337-.027 1.783-.079.026-1.102.367-2.203.997-3.2-.603-1.05-1.679-1.705-2.78-1.705z",
      "stroke": "inherit",
      "stroke-width": ".6"
    }
  }), _c('path', {
    attrs: {
      "d": "M12.698 17c-3.016 0-5.875 0-5.875-3.122 0-3.33 2.754-6.269 5.875-6.269 3.122 0 5.876 2.938 5.876 6.27 0 3.12-2.86 3.12-5.876 3.12zm0-8.604c-2.701 0-5.088 2.57-5.088 5.482 0 2.125 1.547 2.335 5.088 2.335 3.541 0 5.089-.21 5.089-2.335 0-2.911-2.387-5.482-5.089-5.482z",
      "stroke": "inherit",
      "stroke-width": ".6"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }