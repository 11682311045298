








































import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'

import LoadingIcon from '@/assets/img/loading.svg'
import { Route } from 'vue-router'
import { createPropValidator } from '@/composables/prop-validator'

const colors = [ 'blue', 'green', 'gray', 'dark-gray', 'red', 'white', 'white-transparent' ] as const
type AllowedColors = typeof colors[number]

const sizes = [ 'large', 'medium', 'small' ] as const
type AllowedSizes = typeof sizes[number]

export default defineComponent({
    components: {
        LoadingIcon
    },
    props: {
        href: {
            type: String,
            default: null
        },
        text: {
            type: String,
            default: null
        },
        color: {
            type: String as PropType<AllowedColors>,
            default: 'blue',
            validator: createPropValidator({
                componentName: 'AppButton',
                propertyName: 'color',
                allowedValues: colors
            })
        },
        flat: {
            type: Boolean,
            default: false
        },
        secondary: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        size: {
            type: String as PropType<AllowedSizes>,
            default: 'large',
            validator: createPropValidator({
                componentName: 'AppButton',
                propertyName: 'size',
                allowedValues: sizes
            })
        },
        wide: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        to: {
            type: [ String, Object ] as PropType<string | Route>,
            default: ''
        },
        gradient: {
            type: Boolean,
            default: false
        },
        active: {
            type: Boolean,
            default: false
        },
        narrow: {
            type: Boolean,
            default: false
        }
    },
    setup: (props, { emit }) => {
        const innerComponent = computed(() => {
            if (props.to) return 'nuxt-link'
            if (props.href) return 'a'

            return 'button'
        })

        const innerComponentLink = computed(() => {
            if (props.disabled) return undefined

            return props.href
        })

        const onClick = (event: Event): void => {
            if (props.loading) return undefined

            emit('on-click', event)
        }

        return {
            innerComponent,
            innerComponentLink,
            onClick
        }
    }
})
