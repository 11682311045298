var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-input', _vm._g(_vm._b({
    ref: "$input",
    staticClass: "app-input-password",
    attrs: {
      "clearable": false,
      "type": _vm.currentType
    },
    scopedSlots: _vm._u([{
      key: "buttons-append",
      fn: function () {
        return [_c('app-input-button', {
          staticClass: "app-input-password__button",
          attrs: {
            "disabled": _vm.disabled
          },
          on: {
            "on-click": _vm.toggleVisibility
          }
        }, [_c('transition', {
          attrs: {
            "name": "fade"
          }
        }, [_vm.passwordIsVisible ? _c('eye-opened-icon', {
          staticClass: "app-input-password__icon app-input-password__icon--opened"
        }) : _c('eye-closed-icon', {
          staticClass: "app-input-password__icon app-input-password__icon--closed"
        })], 1)], 1)];
      },
      proxy: true
    }])
  }, 'app-input', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners));
}
var staticRenderFns = []

export { render, staticRenderFns }