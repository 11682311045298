var render = function () {
  var _vm$signUp, _vm$signUp$config;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sign-up__password-step"
  }, [!_vm.hideTitle ? _c('div', {
    staticClass: "sign-up__title"
  }, [((_vm$signUp = _vm.signUp) === null || _vm$signUp === void 0 ? void 0 : _vm$signUp.type) === 'customer' ? [_vm._v("\n            Профиль зарегистрирован\n        ")] : [_vm._v("\n            Профиль партнера зарегистрирован\n        ")]], 2) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "sign-up__text"
  }, [_vm._v("\n        А теперь придумайте пароль для аккаунта:\n    ")]), _vm._v(" "), _c('app-input-password', {
    directives: [{
      name: "validate-field",
      rawName: "v-validate-field.lazy.eager",
      value: {
        validation: _vm.validation
      },
      expression: "{ validation }",
      modifiers: {
        "lazy": true,
        "eager": true
      }
    }],
    staticClass: "sign-up__password",
    attrs: {
      "name": "password",
      "placeholder": "Новый пароль",
      "inspectable": "",
      "errors": _vm.errors.password
    },
    on: {
      "on-search": _vm.setPassword
    },
    model: {
      value: _vm.form.password,
      callback: function ($$v) {
        _vm.$set(_vm.form, "password", $$v);
      },
      expression: "form.password"
    }
  }), _vm._v(" "), _c('app-button', {
    staticClass: "sign-up__set-password",
    attrs: {
      "text": "Новый пароль",
      "wide": "",
      "color": (_vm$signUp$config = _vm.signUp.config) === null || _vm$signUp$config === void 0 ? void 0 : _vm$signUp$config.buttonsColor,
      "loading": _vm.isLoading
    },
    on: {
      "on-click": _vm.setPassword
    }
  }, [_vm._v("\n        Подтвердить\n    ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }