import { render, staticRenderFns } from "./AppCopy.vue?vue&type=template&id=8211c900&scoped=true&"
import script from "./AppCopy.vue?vue&type=script&lang=ts&"
export * from "./AppCopy.vue?vue&type=script&lang=ts&"
import style0 from "./AppCopy.vue?vue&type=style&index=0&id=8211c900&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8211c900",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppTooltip: require('/var/www/app/src/components/ui/AppTooltip.vue').default})
