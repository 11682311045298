var render = function () {
  var _class;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: (_class = {
      'base-aside': true
    }, _class[("base-aside--" + (_vm.placement))] = true, _class['base-aside--open'] = _vm.isShowing, _class)
  }, [_vm._t("default", null, null, {
    isShowing: _vm.isShowing,
    open: _vm.open,
    toggle: _vm.toggle,
    close: _vm.close
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }