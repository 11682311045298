var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-aside', _vm._g(_vm._b({
    ref: "$aside",
    staticClass: "catalog-aside",
    attrs: {
      "hide-background": "",
      "disable-body-lock": ""
    }
  }, 'app-aside', _vm.$props, false), _vm.$listeners), [_c('div', {
    staticClass: "catalog-aside__content"
  }, [_c('catalog-back-button', {
    staticClass: "catalog-aside__back",
    attrs: {
      "placement": _vm.placement
    },
    on: {
      "on-click": _vm.close
    }
  }), _vm._v(" "), _c('app-divider', {
    staticClass: "catalog-aside__divider"
  }), _vm._v(" "), _vm._t("default", null, null, {
    open: _vm.open,
    close: _vm.close,
    toggle: _vm.toggle,
    isShowing: _vm.isShowing
  })], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }