



























import {
    computed,
    defineComponent,
    nextTick,
    onBeforeUnmount,
    PropType,
    ref
} from '@nuxtjs/composition-api'

const positions = [
    'top',
    'bottom',
    'right',
    'left'
] as const

const contentAlignments = [
    'start',
    'center',
    'end'
] as const

type Position = typeof positions[number]
type Alignment = typeof contentAlignments[number]
export type Placement = `${Position}-${Alignment}`

type Axes = 'x' | 'y' | 'both'

type Offset = `${number}`
    | `${number} ${number}`
    | `${number} ${number} ${number}`
    | `${number} ${number} ${number} ${number}`

export default defineComponent({
    props: {
        manual: {
            type: Boolean,
            default: false
        },
        transitionName: {
            type: String,
            default: 'fade'
        },
        placement: {
            type: String as PropType<Placement>,
            default: 'bottom-start' as Placement
        },
        flip: {
            type: String as PropType<Axes | null>,
            default: null
        },
        pageOffset: {
            type: String as PropType<Offset>,
            default: '10'
        },
        sticky: {
            type: String as PropType<Axes | null>,
            default: null
        },
        stretch: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    setup: (props, { emit }) => {
        const $element = ref<HTMLDivElement | null>(null)
        const $content = ref<HTMLDivElement | null>(null)

        const offsetSizes = computed(() => {
            const [ top, right, bottom, left ] = props.pageOffset.split(/ +/).map(side => parseFloat(side))

            if (!right) {
                return {
                    top,
                    right: top,
                    bottom: top,
                    left: top
                }
            }

            if (!bottom) {
                return {
                    top,
                    right,
                    bottom: top,
                    left: right
                }
            }

            if (!left) {
                return {
                    top,
                    right,
                    bottom,
                    left: right
                }
            }

            return {
                top,
                right,
                bottom,
                left
            }
        })
        const getOverflowSize = (element: HTMLElement) => {
            const {
                x, y, width, height
            } = element.getBoundingClientRect()
            const { innerWidth, innerHeight } = window

            return {
                top: y - offsetSizes.value.top,
                right: (innerWidth - offsetSizes.value.right) - (x + width),
                bottom: (innerHeight - offsetSizes.value.bottom) - (y + height),
                left: x - offsetSizes.value.left
            }
        }
        const isOverflow = (element: HTMLElement) => {
            const {
                bottom, left, right, top
            } = getOverflowSize(element)

            return {
                right: right < 0,
                left: left < 0,
                top: top < 0,
                bottom: bottom < 0
            }
        }

        const splitPlacement = (placement: Placement) => {
            const [ position, alignment ] = placement.split('-')

            return {
                position: position as Position,
                alignment: alignment as Alignment
            }
        }

        const actualPlacement = ref(props.placement)
        const setPosition = () => {
            actualPlacement.value = props.placement

            nextTick(() => {
                if (!$content.value) return undefined

                let { position, alignment } = splitPlacement(actualPlacement.value)
                const flipX = props.flip === 'x' || props.flip === 'both'
                const flipY = props.flip === 'y' || props.flip === 'both'

                const overflow = isOverflow($content.value)

                if (
                    ((overflow.top && (position === 'left' || position === 'right') && flipY)
                        || (overflow.left && (position === 'top' || position === 'bottom') && flipX))
                    && alignment !== 'start'
                ) {
                    alignment = 'start'
                }

                if (
                    ((overflow.bottom && (position === 'left' || position === 'right') && flipY)
                        || (overflow.right && (position === 'top' || position === 'bottom') && flipX))
                    && alignment !== 'end'
                ) {
                    alignment = 'end'
                }

                if (overflow.bottom && position === 'bottom' && flipY) {
                    position = 'top'
                }

                if (overflow.top && position === 'top' && flipY) {
                    position = 'bottom'
                }

                if (overflow.left && position === 'left' && flipX) {
                    position = 'right'
                }

                if (overflow.right && position === 'right' && flipX) {
                    position = 'left'
                }

                actualPlacement.value = `${position}-${alignment}`
            })
        }

        const offset = ref({
            x: 0,
            y: 0
        })
        const setOffset = () => {
            if (!props.sticky) return undefined

            Object.assign(offset.value, { x: 0, y: 0 })

            nextTick(() => {
                if (!$content.value) return undefined

                const overflow = getOverflowSize($content.value)

                const stickyX = props.sticky === 'both' || props.sticky === 'x'
                const stickyY = props.sticky === 'both' || props.sticky === 'y'

                if (stickyY && overflow.top < 0) {
                    offset.value.y = Math.abs(overflow.top)
                }

                if (stickyX && overflow.left < 0) {
                    offset.value.y = Math.abs(overflow.left)
                }

                if (stickyY && overflow.bottom < 0) {
                    offset.value.x = overflow.bottom
                }

                if (stickyX && overflow.right < 0) {
                    offset.value.x = overflow.right
                }
            })
        }

        const contentStyles = computed(() => {
            const {
                x, y, top, left
            } = {
                'top-start': { y: -100 },
                'top-center': { left: 50, x: -50, y: -100 },
                'top-end': { left: 100, x: -100, y: -100 },
                'bottom-start': { top: 100 },
                'bottom-center': { left: 50, top: 100, x: -50 },
                'bottom-end': { left: 100, top: 100, x: -100 },
                'right-start': { left: 100 },
                'right-center': { left: 100, top: 50, y: -50 },
                'right-end': { left: 100, top: 100, y: -100 },
                'left-start': { x: -100 },
                'left-center': { top: 50, x: -100, y: -50 },
                'left-end': { top: 100, x: -100, y: -100 }
            }[actualPlacement.value]

            const transform = {
                x: `calc(${x || 0}% + (${offset.value.x}px))`,
                y: `calc(${y || 0}% + (${offset.value.y}px))`
            }

            return {
                top: `${top || 0}%`,
                left: `${left || 0}%`,
                maxWidth: `calc(100vw - ${offsetSizes.value.left}px - ${offsetSizes.value.right}px)`,
                maxHeight: `calc(100vh - ${offsetSizes.value.top}px - ${offsetSizes.value.bottom}px)`,
                transform: `translate(${transform.x}, ${transform.y})`
            }
        })

        const clickWatcher = (event: Event) => {
            if ($element.value?.contains(event.target as Node)) {
                return undefined
            }

            close()
        }
        const positionWatcher = () => {
            setPosition()

            nextTick(() => setOffset())
        }
        const clearWatchers = () => {
            window.removeEventListener('scroll', positionWatcher)
            window.removeEventListener('resize', positionWatcher)
            window.removeEventListener('click', clickWatcher, { capture: true })
        }

        const isOpen = ref(false)
        const open = () => {
            if (props.disabled) return undefined

            isOpen.value = true

            if (props.flip) {
                positionWatcher()
                window.addEventListener('scroll', positionWatcher)
                window.addEventListener('resize', positionWatcher)
            }

            if (!props.manual) {
                window.addEventListener('click', clickWatcher, { capture: true })
            }

            emit('on-open')
        }
        const close = () => {
            if (props.disabled) return undefined

            isOpen.value = false

            clearWatchers()

            emit('on-close')
        }
        const toggle = () => {
            if (props.disabled) return undefined

            isOpen.value ? close() : open()

            emit('on-toggle')
        }

        onBeforeUnmount(() => {
            clearWatchers()
        })

        return {
            isOpen,
            open,
            close,
            actualPlacement,
            $content,
            $element,
            toggle,
            contentStyles
        }
    }
})
