var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header-unread-button', _vm._b({
    staticClass: "header-notifications-button",
    on: {
      "on-click": function ($event) {
        return _vm.$router.push('/notifications');
      }
    }
  }, 'header-unread-button', _vm.$props, false), [_c('bell-icon', {
    staticClass: "header-notifications-button__icon"
  }), _vm._v(" "), _vm._t("text")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }