var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    class: ['app-widget', {
      'app-widget--vertical': _vm.vertical,
      'app-widget__news': _vm.newsHorisontal,
      'app-widget__news--vertical': _vm.newsVertical
    }],
    attrs: {
      "href": _vm.widgetUrl,
      "target": _vm.target
    },
    on: {
      "click": _vm.onClick
    }
  }, [_c('img', {
    staticClass: "app-widget__image",
    attrs: {
      "src": _vm.widget.imageUrl
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }