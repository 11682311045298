var render = function () {
  var _vm$user$profile;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    staticClass: "the-header"
  }, [_vm.isShowAsClaimButton ? _c('div', {
    staticClass: "the-header__impersonation"
  }, [_c('app-button', {
    attrs: {
      "size": "small",
      "color": "white-transparent"
    },
    on: {
      "on-click": _vm.logout
    }
  }, [_vm._v("\n            Вернуться в аккаунт администратора\n        ")])], 1) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "the-header__hat",
    class: {
      'the-header__hat': true,
      'the-header__hat--search-open': _vm.openedAside === 'search'
    }
  }, [_c('div', {
    staticClass: "the-header__content"
  }, [_c('div', {
    staticClass: "the-header__navigation"
  }, [!_vm.hideCatalogAside ? _c('header-burger', {
    staticClass: "the-header__catalog-button",
    attrs: {
      "open": _vm.openedAside === 'catalog'
    },
    on: {
      "on-close": _vm.closeAside,
      "on-open": function ($event) {
        return _vm.openAside('catalog');
      }
    }
  }) : _vm._e(), _vm._v(" "), !_vm.hideSearch ? _c('header-search-button', {
    staticClass: "the-header__search-button",
    attrs: {
      "open": _vm.openedAside === 'search'
    },
    on: {
      "on-close": _vm.closeAside,
      "on-open": function ($event) {
        return _vm.openAside('search');
      }
    }
  }) : _vm._e()], 1), _vm._v(" "), _c('nuxt-link', {
    staticClass: "the-header__logo-link",
    attrs: {
      "to": "/"
    }
  }, [_c('logo', {
    staticClass: "the-header__logo"
  })], 1), _vm._v(" "), [!_vm.hideSearch ? _c('header-search-bar', {
    staticClass: "the-header__search-bar",
    on: {
      "on-search": _vm.onSearch
    },
    model: {
      value: _vm.searchQuery,
      callback: function ($$v) {
        _vm.searchQuery = $$v;
      },
      expression: "searchQuery"
    }
  }) : _vm._e(), _vm._v(" "), !_vm.hideSearch && _vm.isDesktop ? _c('header-search-button', {
    staticClass: "the-header__search-button",
    attrs: {
      "open": _vm.openedAside === 'search'
    },
    on: {
      "on-close": _vm.closeAside,
      "on-open": function ($event) {
        return _vm.openAside('search');
      }
    }
  }) : _vm._e(), _vm._v(" "), _c('header-navigation', {
    staticClass: "the-header__navigation-menu",
    attrs: {
      "shadowed": _vm.openedAside !== 'search',
      "navigation-items": _vm.navigationItems
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "the-header__notifications"
  }, [!_vm.hideNotifications ? _c('header-notifications-button', {
    staticClass: "the-header__notifications-button",
    attrs: {
      "unread-count": _vm.unreadNotificationsCount
    },
    on: {
      "on-click": function ($event) {
        return _vm.$emit('on-notifications');
      }
    }
  }) : _vm._e(), _vm._v(" "), _vm.channelId ? _c('header-messages-button', {
    staticClass: "the-header__messages-button",
    attrs: {
      "channel-id": _vm.channelId,
      "unread-count": _vm.unreadMessagesCount
    },
    on: {
      "on-click": function ($event) {
        return _vm.$emit('on-messages');
      }
    }
  }) : _vm._e()], 1)], _vm._v(" "), _vm.isDesktop ? _c('header-cabinet-type') : _vm._e(), _vm._v(" "), _c('header-profile', {
    staticClass: "the-header__profile",
    attrs: {
      "open": _vm.openedAside === 'profile',
      "image": ((_vm$user$profile = _vm.user.profile) === null || _vm$user$profile === void 0 ? void 0 : _vm$user$profile.avatarId) || '',
      "name": _vm.userFullName,
      "unread-count": _vm.unreadMessagesCount + _vm.unreadNotificationsCount
    },
    on: {
      "on-close": _vm.closeAside,
      "on-open": function ($event) {
        return _vm.openAside('profile');
      }
    }
  })], 2)]), _vm._v(" "), !_vm.hideSearch ? _c('app-aside', {
    staticClass: "the-header__aside",
    attrs: {
      "is-open": _vm.openedAside === 'search'
    },
    on: {
      "on-close": _vm.closeAside
    }
  }, [_c('div', {
    staticClass: "the-header__aside-content"
  }, [_c('header-search-bar', {
    staticClass: "the-header__search-bar the-header__search-bar--aside",
    on: {
      "on-search": _vm.onSearch
    },
    model: {
      value: _vm.searchQuery,
      callback: function ($$v) {
        _vm.searchQuery = $$v;
      },
      expression: "searchQuery"
    }
  })], 1)]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }