var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('base-spoiler', {
    ref: "$spoiler",
    staticClass: "catalog-spoiler",
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('div', {
          staticClass: "catalog-spoiler__content"
        }, [_vm._t("default")], 2)];
      },
      proxy: true
    }], null, true)
  });
}
var staticRenderFns = []

export { render, staticRenderFns }