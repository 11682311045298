import { render, staticRenderFns } from "./-HeaderNavigationItem.vue?vue&type=template&id=1bfa086b&scoped=true&"
import script from "./-HeaderNavigationItem.vue?vue&type=script&lang=ts&"
export * from "./-HeaderNavigationItem.vue?vue&type=script&lang=ts&"
import style0 from "./-HeaderNavigationItem.vue?vue&type=style&index=0&id=1bfa086b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bfa086b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppStatus: require('/var/www/app/src/components/ui/AppStatus.vue').default})
