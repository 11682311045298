var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    class: {
      'app-select-option': true,
      'app-select-option--highlighted': _vm.highlighted
    }
  }, [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }