





















































































import {
    PropType,
    computed,
    defineComponent,
    onMounted,
    onUnmounted,
    ref,
    watch,
    useRoute
} from '@nuxtjs/composition-api'

import { MountingPortal } from 'portal-vue'

import {
    CatalogItem as CatalogItemInterface,
    isCatalogItem
} from '@/interfaces/catalog.interface'
import { useDeviceWatcher } from '@/composables/device-watcher'
import { httpLinkRegExp } from '@/composables/regexps'
import { createPropValidator } from '@/composables/prop-validator'

import { Placement, placements } from '@/components/ui/BaseAside.vue'
import SpoilerIcon from '@/assets/img/spoiler.svg'

import CatalogAside from './-CatalogAside.vue'
import CatalogSpoiler from './-CatalogSpoiler.vue'
import { generalizeLinkImage } from '@/composables/images'

export default defineComponent({
    name: 'CatalogItem',
    components: {
        SpoilerIcon,
        CatalogAside,
        MountingPortal,
        CatalogSpoiler
    },
    props: {
        item: {
            type: Object as PropType<CatalogItemInterface | null>,
            valiadtor: createPropValidator({
                componentName: 'CatalogItem',
                propertyName: 'item',
                validator: isCatalogItem
            }),
            default: null
        },
        nested: {
            type: Boolean,
            default: false
        },
        baged: {
            type: Boolean,
            default: false
        },
        shadow: {
            type: Boolean,
            default: false
        },
        aside: {
            type: Boolean,
            default: false
        },
        placement: {
            type: String as PropType<Placement>,
            default: 'left',
            validator: createPropValidator({
                componentName: 'CatalogItem',
                propertyName: 'placement',
                allowedValues: placements
            })
        }
    },
    setup: (props, { emit, slots }) => {
        const $nestedWrapper = ref<
            InstanceType<typeof CatalogSpoiler> | InstanceType<typeof CatalogAside> | null
        >(null)
        const isOpen = computed(
            () => $nestedWrapper.value?.isOpen || $nestedWrapper.value?.isShowing
        )
        const fullUrl = ref(window.location.href)
        const route = useRoute()

        watch(route, () => {
            fullUrl.value = window.location.href.split('?')[0].toString()
        })

        const headComponent = computed(() => {
            const { url, nested } = props.item || {}

            if (nested?.length || !url?.trim()) {
                return 'button'
            }

            if (httpLinkRegExp.test(url)) {
                return 'a'
            }

            return 'nuxt-link'
        })
        const getImageLink = computed(() => {
            if (httpLinkRegExp.test(props.item?.icon)) {
                return props.item?.icon
            }

            return generalizeLinkImage(props.item?.icon)
        })
        const nestedWrapperComponent = computed(
            () => (props.aside ? 'catalog-aside' : 'catalog-spoiler')
        )

        const isTargetParam = computed(() => {
            return !httpLinkRegExp.test(props.item?.url) || props.item?.url?.includes('workle')
                ? '_self'
                : '_blank'
        })

        const displayNested = computed(
            () => Boolean(props.item?.nested?.length || slots.content)
        )
        const onClick = () => {
            emit('on-click', props.item, props.nested)

            if (!displayNested.value) {
                return undefined
            }

            $nestedWrapper.value?.toggle()
        }

        const isbaged = computed(
            () => props.item?.nested?.some(nestedItem => nestedItem.badgeColor)
        )

        const {
            defineDevice,
            currentDevice,
            isDesktop
        } = useDeviceWatcher([
            { alias: 'mobile', breakpoint: 0 },
            { alias: 'desktop', breakpoint: 1024 }
        ])

        onMounted(() => {
            defineDevice()

            window.addEventListener('resize', defineDevice)

            if (props.item?.expanded && isDesktop.value) {
                $nestedWrapper.value?.open()
            }
        })

        onUnmounted(() => {
            window.removeEventListener('resize', defineDevice)
        })

        return {
            $nestedWrapper,
            onClick,
            isbaged,
            headComponent,
            isOpen,
            displayNested,
            nestedWrapperComponent,
            currentDevice,
            isDesktop,
            getImageLink,
            isTargetParam,
            fullUrl
        }
    }
})
