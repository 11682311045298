















































import { RawLocation } from 'vue-router'

import {
    PropType,
    defineComponent,
    onMounted,
    ref,
    useContext
} from '@nuxtjs/composition-api'

import { CatalogItem as CatalogItemInterface } from '@/interfaces/catalog.interface'
import { Components as Customer } from '@/types/client.customer'

import CatalogProfile from './-CatalogProfile.vue'
import CatalogBalance from './-CatalogBalance.vue'
import CatalogSocials from './-CatalogSocials.vue'
import CatalogProfileSection from './-CatalogProfileSection.vue'
import AppWidgetSkeleton from '@/components/ui/AppWidgetSkeleton.vue'
import CatalogItemSkeleton from './-CatalogItemSkeleton.vue'
import LogoutIcon from '@/assets/img/log-out.svg'

export default defineComponent({
    components: {
        CatalogProfile,
        CatalogBalance,
        CatalogSocials,
        CatalogProfileSection,
        LogoutIcon,
        AppWidgetSkeleton,
        CatalogItemSkeleton
    },
    props: {
        catalogItems: {
            type: Array as PropType<CatalogItemInterface[]>,
            default: () => []
        },
        profileLink: {
            type: [ String, Object ] as PropType<RawLocation | null>,
            default: null
        },
        hideProfileInfo: {
            type: Boolean,
            default: false
        },
        aside: {
            type: Boolean,
            default: false
        }
    },
    setup: () => {
        const { $apiCustomer, $auth } = useContext()

        const widget = ref<Customer.Schemas.WidgetElement | null>(null)
        const userSegment = ref<Customer.Schemas.UserSegment | null>(null)
        const DEFAULT_SEGMENT = 'beginner-all-achieve'

        const fetchWidget = async () => {
            const { data: [ firstWidgetData ] } = await $apiCustomer.widgetsGet({
                segmentAlias: userSegment.value?.segmentAlias as string || DEFAULT_SEGMENT,
                widgetPlaces: [ 'under-menu' ]
            })

            widget.value = firstWidgetData
        }

        const fetchUserSegment = async () => {
            const { data: userSegmentResponse } = await $apiCustomer.segmentsGetUserSegment()

            userSegment.value = userSegmentResponse
        }

        const logout = () => $auth.logout()

        onMounted(async () => {
            try {
                await fetchUserSegment()
                await fetchWidget()
            } catch (error) {
                console.error(error)
            }
        })

        return {
            logout,
            widget
        }
    }
})
