var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "app-input"
  }, [_c('app-input-frame', {
    staticClass: "app-input__input-wrapper",
    attrs: {
      "tag": "label",
      "active": _vm.focused,
      "error": _vm.hasErrors,
      "disabled": _vm.disabled
    }
  }, [_c('input', _vm._b({
    ref: "$input",
    class: {
      'app-input__core': true,
      'app-input__core--password': _vm.value && _vm.type === 'password'
    },
    attrs: {
      "type": _vm.actualType,
      "title": _vm.displayingTitle,
      "name": _vm.name,
      "readonly": _vm.readonly,
      "disabled": _vm.disabled,
      "inputmode": _vm.inputmode || _vm.inputModeByType,
      "autocomplete": _vm.autocomplete,
      "placeholder": _vm.placeholder,
      "maxlength": _vm.maxlength
    },
    domProps: {
      "value": _vm.value
    },
    on: {
      "input": _vm.onInput,
      "focus": _vm.onFocus,
      "blur": _vm.onBlur
    }
  }, 'input', _vm.$attrs, false)), _vm._v(" "), _c('div', {
    staticClass: "app-input__buttons",
    on: {
      "mousedown": function ($event) {
        $event.preventDefault();
      }
    }
  }, [_vm._t("buttons-prepend"), _vm._v(" "), _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.value && !_vm.disabled && !_vm.readonly && _vm.clearable ? _c('app-input-button', {
    staticClass: "app-input__button",
    attrs: {
      "disabled": _vm.disabled,
      "error": _vm.hasErrors
    },
    on: {
      "on-click": _vm.onClear
    }
  }, [_c('cross-icon', {
    staticClass: "app-input__icon app-input__icon--cross"
  })], 1) : _vm._e()], 1), _vm._v(" "), _vm._t("buttons-append")], 2)]), _vm._v(" "), _vm.maxlength && _vm.showletterslimit ? _c('div', {
    staticClass: "app-input__max-length"
  }, [_vm._v("\n        " + _vm._s(String(_vm.value).length) + " / " + _vm._s(_vm.maxlength) + "\n    ")]) : _vm._e(), _vm._v(" "), _c('app-errors-list', {
    staticClass: "app-input__errors",
    attrs: {
      "errors": _vm.errors
    }
  }), _vm._v(" "), _vm.hint || _vm.$slots.hint ? _c('div', {
    staticClass: "app-input__hint"
  }, [_vm._t("hint", function () {
    return [_vm._v("\n            " + _vm._s(_vm.hint) + "\n        ")];
  })], 2) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }