













import { computed, defineComponent, ref } from '@nuxtjs/composition-api'

import BaseSpoiler from '@/components/ui/BaseSpoiler.vue'

export default defineComponent({
    setup: () => {
        const $spoiler = ref<InstanceType<typeof BaseSpoiler> | null>(null)
        const isOpen = computed(() => $spoiler.value?.isOpen)
        const open = () => $spoiler.value?.open()
        const close = () => $spoiler.value?.close()
        const toggle = () => $spoiler.value?.toggle()

        return {
            $spoiler,
            isOpen,
            open,
            close,
            toggle
        }
    }
})
