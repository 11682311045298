var render = function () {
  var _vm$item, _vm$item2, _vm$item3, _vm$item4, _vm$item5;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "catalog-item"
  }, [_c(_vm.headComponent, {
    tag: "component",
    class: {
      'catalog-item__head': true,
      'catalog-item__head--nested': _vm.nested,
      'catalog-item__head--baged': _vm.baged,
      'catalog-item__head--shadow': _vm.shadow,
      'catalog-item__head--aside': _vm.aside,
      'catalog-item__head--contain': ((_vm$item = _vm.item) === null || _vm$item === void 0 ? void 0 : _vm$item.url) === _vm.fullUrl
    },
    attrs: {
      "href": (_vm$item2 = _vm.item) === null || _vm$item2 === void 0 ? void 0 : _vm$item2.url,
      "target": _vm.isTargetParam,
      "to": (_vm$item3 = _vm.item) === null || _vm$item3 === void 0 ? void 0 : _vm$item3.url
    },
    on: {
      "click": _vm.onClick
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.onClick.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "catalog-item__name-wrapper"
  }, [((_vm$item4 = _vm.item) !== null && _vm$item4 !== void 0 && _vm$item4.icon || _vm.$slots.icon) && !_vm.nested ? _c('div', {
    class: {
      'catalog-item__icon': true,
      'catalog-item__icon--aside': _vm.aside
    }
  }, [_vm._t("icon", function () {
    return [_c('img', {
      staticClass: "catalog-item__icon-image",
      attrs: {
        "src": _vm.getImageLink
      }
    }), _vm._v(" "), _c('img', {
      staticClass: "catalog-item__icon-image catalog-item__icon-image--colored",
      attrs: {
        "src": _vm.getImageLink
      }
    })];
  }, null, _vm.item || {})], 2) : _vm._e(), _vm._v(" "), (_vm$item5 = _vm.item) !== null && _vm$item5 !== void 0 && _vm$item5.badgeColor ? _c('div', {
    staticClass: "catalog-item__badge",
    style: {
      background: _vm.item.badgeColor
    }
  }) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "catalog-item__name"
  }, [_vm._t("default", function () {
    var _vm$item6;

    return [_vm._v("\n                    " + _vm._s((_vm$item6 = _vm.item) === null || _vm$item6 === void 0 ? void 0 : _vm$item6.name) + "\n                ")];
  }, null, _vm.item || {})], 2)]), _vm._v(" "), _vm.displayNested ? _c('spoiler-icon', {
    class: {
      'catalog-item__spoiler-icon': true,
      'catalog-item__spoiler-icon--aside': _vm.aside,
      'catalog-item__spoiler-icon--open': _vm.isOpen
    }
  }) : _vm._e()], 1), _vm._v(" "), _vm.displayNested ? _c(_vm.nestedWrapperComponent, {
    ref: "$nestedWrapper",
    tag: "component",
    class: ['catalog-item__nested-wrapper', ("catalog-item__nested-wrapper--" + (_vm.nestedWrapperComponent))],
    attrs: {
      "placement": _vm.placement
    }
  }, [_vm._t("content", function () {
    return _vm._l(_vm.item.nested, function (nestedItem) {
      return _c('catalog-item', {
        key: nestedItem.alias,
        attrs: {
          "nested": "",
          "aside": _vm.aside,
          "baged": _vm.isbaged,
          "item": nestedItem
        },
        on: {
          "on-click": function ($event) {
            return _vm.$emit('on-click', $event);
          }
        }
      });
    });
  })], 2) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }