

















import { defineComponent, nextTick, ref } from '@nuxtjs/composition-api'

export default defineComponent({
    props: {
        transitionName: {
            type: String,
            default: 'height-transition'
        },
        tag: {
            type: String,
            default: 'div'
        },
        visibleOverflow: {
            type: Boolean,
            default: false
        }
    },
    setup: (props) => {
        const $component = ref()
        const setComponentHeight = (size: number | null = null) => {
            if (!$component.value) return undefined

            const initialHeight = size === null
                ? $component.value.$el.offsetHeight
                : size || 0
            const {
                paddingTop,
                paddingBottom,
                borderBottomWidth,
                borderTopWidth
            } = window.getComputedStyle($component.value.$el)
            const height = [
                paddingTop,
                paddingBottom,
                borderBottomWidth,
                borderTopWidth
            ].reduce(
                (totalHeight, value) => (totalHeight += parseFloat(value)),
                initialHeight
            )

            $component.value.$el.style.height = `${height}px`
        }
        const clearComponentHeight = () => {
            $component.value.$el.style.height = ''
        }
        const setComponentHeightByElement = (element: HTMLElement) => {
            nextTick(() => {
                const { position } = window.getComputedStyle($component.value.$el)

                $component.value.$el.style.display = 'contents'
                element.style.position = position
            })

            nextTick(() => {
                const height = element.offsetHeight

                $component.value.$el.style.display = ''
                element.style.position = ''

                setComponentHeight(height)
            })
        }
        const injectTransitionStyles = (element: HTMLElement) => {
            const {
                transitionTimingFunction,
                transitionDelay,
                transitionDuration
            } = window.getComputedStyle(element)

            if (!props.visibleOverflow) {
                $component.value.$el.style.overflow = 'hidden'
            }

            $component.value.$el.style.transition = [
                'height',
                transitionDuration,
                transitionTimingFunction,
                transitionDelay
            ].join(' ')
        }
        const clearTransitionStyles = () => {
            $component.value.$el.style.transition = ''
            $component.value.$el.style.overflow = ''
        }

        const beforeTransition = () => {
            setComponentHeight()
        }
        const afterTransition = () => {
            clearTransitionStyles()
            clearComponentHeight()
        }

        const leave = (element: HTMLElement) => {
            injectTransitionStyles(element)
            setComponentHeight(0)
        }
        const enter = (element: HTMLElement) => {
            injectTransitionStyles(element)
            setComponentHeightByElement(element)
        }

        return {
            $component,
            leave,
            afterTransition,
            beforeTransition,
            enter
        }
    }
})
