var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 19 20",
      "fill": "inherit"
    }
  }, _vm.$listeners), [_c('path', {
    attrs: {
      "d": "M13.632 10.628a5.516 5.516 0 10-7.355 0A7.882 7.882 0 001 18.056a.944.944 0 101.888 0 6 6 0 015.994-5.994h2.17a6 6 0 015.994 5.994.944.944 0 001.888 0 7.882 7.882 0 00-5.302-7.428zM9.955 2.936a3.61 3.61 0 110 7.22 3.61 3.61 0 010-7.22z",
      "stroke": "inherit",
      "stroke-width": ".09"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }