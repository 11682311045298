












import { defineComponent } from '@nuxtjs/composition-api'

import ArrowIcon from '@/assets/img/pointer-right.svg'

export default defineComponent({
    components: {
        ArrowIcon
    }
})
