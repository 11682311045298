















import { defineComponent, useRouter } from '@nuxtjs/composition-api'

import LetterIcon from '@/assets/img/letter.svg'

import HeaderUnreadButton from './-HeaderUnreadButton.vue'

export default defineComponent({
    components: {
        HeaderUnreadButton,
        LetterIcon
    },
    props: {
        ...HeaderUnreadButton.props,
        channelId: {
            type: String,
            default: ''
        }
    }
})
