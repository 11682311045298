var render = function () {
  var _vm$signUp, _vm$signUp$config;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sign-up__finish-step"
  }, [!_vm.hideTitle ? _c('div', {
    staticClass: "sign-up__title"
  }, [((_vm$signUp = _vm.signUp) === null || _vm$signUp === void 0 ? void 0 : _vm$signUp.type) === 'customer' ? [_vm._v("\n            Профиль активирован!\n        ")] : [_vm._v("\n            Профиль партнера активирован!\n        ")]], 2) : _vm._e(), _vm._v(" "), _c('app-button', {
    staticClass: "sign-up__go-to-workle",
    attrs: {
      "wide": "",
      "color": (_vm$signUp$config = _vm.signUp.config) === null || _vm$signUp$config === void 0 ? void 0 : _vm$signUp$config.buttonsColor
    },
    on: {
      "on-click": _vm.finish
    }
  }, [_vm._v("\n        Перейти на Workle Pro\n    ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }