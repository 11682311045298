








































import { PropType, defineComponent, ref } from '@nuxtjs/composition-api'
import { debounce } from '@/composables/debounce'

import CopyIcon from '@/assets/img/copy.svg'
import { Placement } from '@popperjs/core'

export default defineComponent({
    components: {
        CopyIcon
    },
    props: {
        toCopy: {
            type: String,
            default: ''
        },
        tooltipTime: {
            type: Number,
            default: 3000
        },
        noIcon: {
            type: Boolean,
            default: false
        },
        link: {
            type: Boolean,
            default: false
        },
        placement: {
            type: String as PropType<Placement>,
            default: 'bottom'
        }
    },
    setup: (props, { emit }) => {
        const isCopied = ref(false)
        const copyDebounce = debounce(() => (isCopied.value = false), props.tooltipTime)
        const copy = async () => {
            try {
                await navigator.clipboard.writeText(props.toCopy)

                emit('on-copy', props.toCopy)
                isCopied.value = true

                copyDebounce()
            } catch (error) {
                console.error("[Vue warn]: Didn't copied")
            }
        }

        const onClick = () => {
            if (!props.noIcon && !props.link) return undefined

            copy()
        }

        return {
            isCopied,
            copy,
            onClick
        }
    }
})
