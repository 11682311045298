










import { defineComponent, PropType } from '@nuxtjs/composition-api'

export default defineComponent({
    props: {
        status: {
            type: String as PropType<'Enabled' | 'Disabled' | 'Unknown'>,
            default: 'Unknown'
        }
    }
})

