

































































































































import {
    computed,
    defineComponent,
    nextTick,
    PropType,
    ref,
    watch
} from '@nuxtjs/composition-api'

import { compareValues } from '@/composables/utils'
import { SelectOption as ISelectOption } from '@/interfaces/select-option.interface'
import { createPropValidator } from '@/composables/prop-validator'
import { isSelectOption } from '@/composables/select-option'

import BaseDropdown from '@/components/ui/BaseDropdown.vue'

import ChevronIcon from '@/assets/img/chevron.svg'
import CrossIcon from '@/assets/img/cross.svg'

import AppSelectOption from './-AppSelectOption.vue'
import AppInputFrame from '../AppInput/-AppInputFrame.vue'
import AppInputButton from '../AppInput/-AppInputButton.vue'

export type TransformCallback<T, U> = (option: ISelectOption<T>) => U
export type FilterCallback<T> = (
    query: string,
    option: ISelectOption<T>,
    index: number,
    options: ISelectOption<T>[]
) => boolean

export default defineComponent({
    components: {
        AppSelectOption,
        ChevronIcon,
        CrossIcon,
        AppInputFrame,
        AppInputButton
    },
    props: {
        value: {
            type: [ Object, String, Array, Number ] as PropType<unknown>,
            default: null
        },
        name: {
            type: String,
            required: true
        },
        options: {
            type: Array as PropType<ISelectOption<unknown>[]>,
            valiadtor: createPropValidator({
                componentName: 'AppSelect',
                propertyName: 'options',
                validator: value => Array.isArray(value) && value.every(isSelectOption)
            }),
            default: () => []
        },
        transform: {
            type: Function as PropType<TransformCallback<unknown, unknown>>,
            default: (option: ISelectOption<unknown> | null) => option?.value || null,
            validator: createPropValidator({
                componentName: 'AppSelect',
                propertyName: 'transform',
                validator: value => typeof value === 'function'
            })
        },
        filter: {
            type: Function as PropType<FilterCallback<unknown>>,
            validator: createPropValidator({
                componentName: 'AppSelect',
                propertyName: 'filter',
                validator: value => typeof value === 'function'
            }),
            default: (query: string, option: ISelectOption<unknown>) => {
                const transformedQuery = query.toLocaleLowerCase().replace(/ +/g, '')
                const transformedOptionCaption = option.caption
                    .toLocaleLowerCase()
                    .replace(/ +/g, '')

                return transformedOptionCaption.includes(transformedQuery)
            }
        },
        stayOpened: {
            type: Boolean,
            default: false
        },
        stayFocused: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: 'Выберите'
        },
        searchPlaceholder: {
            type: String,
            default: 'Поиск...'
        },
        maxListHeight: {
            type: [ String, Number ] as PropType<string | number>,
            default: '300px'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        searchable: {
            type: Boolean,
            default: false
        },
        filterable: {
            type: Boolean,
            default: false
        },
        clearable: {
            type: Boolean,
            default: false
        },
        errors: {
            type: Array as PropType<string[]>,
            valiadtor: createPropValidator({
                componentName: 'AppSelect',
                propertyName: 'errors',
                validator: value => Array.isArray(value)
                    && value.every(item => typeof item === 'string')
            }),
            default: () => []
        },
        hideInput: {
            type: Boolean,
            default: false
        },
        highlighted: {
            type: Array as PropType<unknown[]>,
            valiadtor: createPropValidator({
                componentName: 'AppSelect',
                propertyName: 'highlighted',
                validator: value => Array.isArray(value)
            }),
            default: () => []
        },
        memorized: {
            type: Object as PropType<ISelectOption<unknown> | null>,
            valiadtor: createPropValidator({
                componentName: 'AppSelect',
                propertyName: 'defaultOption',
                validator: isSelectOption
            }),
            default: null
        },
        dontMemorize: {
            type: Boolean,
            default: false
        },
        dontPlaceSelected: {
            type: Boolean,
            default: false
        }
    },
    setup: (props, { emit }) => {
        const $input = ref<HTMLInputElement | null>(null)

        const isCollapsed = ref(true)
        const showAll = ref(false)
        const searchQuery = ref('')
        const actualMemorized = ref<ISelectOption<unknown> | null>(
            props.memorized
        )
        watch(
            () => props.memorized,
            (value) => {
                actualMemorized.value = value
            }
        )
        const selected = computed(
            () => {
                const concated = actualMemorized.value
                    ? props.options.concat([ actualMemorized.value ])
                    : props.options

                return concated.find(
                    option => compareValues(props.value, props.transform(option))
                )
            }
        )

        const isHighlighted = (
            option: ISelectOption<unknown>
        ) => compareValues(props.value, props.transform(option))
            || props.highlighted.some(item => compareValues(item, props.transform(option)))

        const $dropdown = ref<InstanceType<typeof BaseDropdown> | null>(null)
        const expand = () => {
            if (props.disabled) {
                return undefined
            }
            $input.value?.focus()
            $dropdown.value?.open()
            searchQuery.value = selected.value?.caption || ''
            isCollapsed.value = false
            emit('on-expand')
        }
        const collapse = (force = false) => {
            if (!props.stayOpened || force) {
                $dropdown.value?.close()

                isCollapsed.value = true
                showAll.value = true

                emit('on-collapse')
            }

            if (!props.stayFocused) {
                document.activeElement?.blur()
                nextTick(() => emit('blur', { target: { value: props.value } }))
            }
        }

        const toggle = () => {
            emit('on-toggle')

            if ($dropdown.value?.isOpen) {
                return collapse(true)
            }

            expand()
        }

        const select = (option: ISelectOption<unknown>) => {
            if (isCollapsed.value) {
                return undefined
            }

            if (!props.dontPlaceSelected) {
                searchQuery.value = option.caption
            }

            collapse()

            emit('input', props.transform(option))

            if (!props.dontMemorize) {
                actualMemorized.value = option
            }

            emit('on-select', option)
        }

        const clear = () => {
            searchQuery.value = ''
            actualMemorized.value = null

            collapse()

            emit('input', props.transform(null))
            emit('on-select', props.transform(null))
        }

        const onSearch = () => {
            if (!props.searchable) return undefined

            emit('on-search', searchQuery.value)
        }

        const onInput = (event: InputEvent) => {
            if (!props.searchable && !props.filterable) {
                return undefined
            }

            searchQuery.value = (event.target as HTMLInputElement).value
            showAll.value = false
            emit('on-input', searchQuery.value)

            if (props.filterable) {
                emit('on-filter', searchQuery.value)
            }
        }

        const filteredOptions = computed(() => {
            if (!props.filterable || !searchQuery.value.trim() || showAll.value) {
                return props.options
            }

            return props.options.filter(props.filter.bind(undefined, searchQuery.value))
        })

        const optionsStyles = computed(() => ({
            maxHeight:
                typeof props.maxListHeight === 'number'
                    ? `${props.maxListHeight}px`
                    : String(props.maxListHeight)
        }))

        const isSearching = computed(
            () => $dropdown.value?.isOpen && (props.searchable || props.filterable)
        )
        const displayingValue = computed(
            () => (isSearching.value ? searchQuery.value : selected.value?.caption) || ''
        )
        const activePlaceholder = computed(
            () => (isSearching.value ? props.searchPlaceholder : props.placeholder)
        )

        const hasErrors = computed(() => Boolean(props.errors.length))

        return {
            $dropdown,
            $input,
            activePlaceholder,
            collapse,
            displayingValue,
            expand,
            clear,
            filteredOptions,
            isHighlighted,
            onInput,
            onSearch,
            optionsStyles,
            searchQuery,
            select,
            selected,
            hasErrors,
            toggle
        }
    }
})
