import { render, staticRenderFns } from "./TheGuestHeader.vue?vue&type=template&id=13c8ecdc&scoped=true&"
import script from "./TheGuestHeader.vue?vue&type=script&lang=ts&"
export * from "./TheGuestHeader.vue?vue&type=script&lang=ts&"
import style0 from "./TheGuestHeader.vue?vue&type=style&index=0&id=13c8ecdc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13c8ecdc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SignUpModal: require('/var/www/app/src/components/ui/TheAuth/SignUpModal.vue').default,AppButton: require('/var/www/app/src/components/ui/AppButton.vue').default,AppAside: require('/var/www/app/src/components/ui/AppAside.vue').default})
