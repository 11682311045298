var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "app-select"
  }, [_c('base-dropdown', {
    ref: "$dropdown",
    staticClass: "app-select__dropdown",
    attrs: {
      "flip": "y",
      "stretch": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var isOpen = _ref.isOpen;
        return [_c('app-input-frame', {
          staticClass: "app-select__input-wrapper",
          attrs: {
            "tag": "div",
            "tabindex": "1",
            "active": isOpen,
            "disabled": _vm.disabled,
            "error": _vm.hasErrors
          },
          nativeOn: {
            "focus": function ($event) {
              return _vm.expand.apply(null, arguments);
            },
            "focusout": function ($event) {
              return _vm.collapse(false);
            }
          }
        }, [_c('div', {
          staticClass: "app-select__input"
        }, [_vm._t("input-prepend"), _vm._v(" "), !_vm.hideInput ? _vm._t("input", function () {
          return [_c('input', {
            ref: "$input",
            staticClass: "app-select__core",
            attrs: {
              "autocomplete": "off",
              "readonly": !_vm.searchable && !_vm.filterable,
              "placeholder": _vm.activePlaceholder,
              "name": _vm.name,
              "disabled": _vm.disabled,
              "title": _vm.displayingValue
            },
            domProps: {
              "value": _vm.displayingValue
            },
            on: {
              "focus": _vm.expand,
              "blur": function ($event) {
                return _vm.collapse(false);
              },
              "input": _vm.onInput,
              "keydown": [function ($event) {
                if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
                return _vm.onSearch.apply(null, arguments);
              }, function ($event) {
                if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "escape", undefined, $event.key, undefined)) { return null; }
                return _vm.collapse(true);
              }]
            }
          })];
        }, null, {
          $input: _vm.$input,
          isOpen: isOpen,
          searchPlaceholder: _vm.searchPlaceholder,
          placeholder: _vm.placeholder,
          searchQuery: _vm.searchQuery,
          selected: _vm.selected,
          expand: _vm.expand,
          collapse: _vm.collapse,
          onInput: _vm.onInput
        }) : _vm._e()], 2), _vm._v(" "), _c('div', {
          staticClass: "app-select__buttons",
          on: {
            "mousedown": function ($event) {
              $event.preventDefault();
            }
          }
        }, [_vm.clearable && _vm.value ? _c('app-input-button', {
          staticClass: "app-select__button",
          attrs: {
            "active": isOpen,
            "disabled": _vm.disabled,
            "error": _vm.hasErrors
          },
          on: {
            "on-click": _vm.clear
          }
        }, [_c('cross-icon', {
          class: {
            'app-select__icon': true,
            'app-select__icon--cross': true
          }
        })], 1) : _vm._e(), _vm._v(" "), _c('app-input-button', {
          staticClass: "app-select__button",
          attrs: {
            "active": isOpen,
            "disabled": _vm.disabled,
            "error": _vm.hasErrors
          },
          on: {
            "on-click": _vm.toggle
          }
        }, [_c('chevron-icon', {
          class: {
            'app-select__icon': true,
            'app-select__icon--chevron': true,
            'app-select__icon--open': isOpen
          }
        })], 1)], 1)])];
      }
    }, {
      key: "content",
      fn: function () {
        return [_vm.filteredOptions.length ? _c('ul', {
          staticClass: "app-select__options",
          style: _vm.optionsStyles,
          on: {
            "mousedown": function ($event) {
              $event.preventDefault();
            },
            "scroll": function ($event) {
              return _vm.$emit('on-scroll', $event);
            }
          }
        }, _vm._l(_vm.filteredOptions, function (option) {
          return _c('app-select-option', {
            key: option.value,
            staticClass: "app-select__option",
            attrs: {
              "highlighted": _vm.isHighlighted(option)
            },
            nativeOn: {
              "click": function ($event) {
                return _vm.select(option);
              }
            }
          }, [_vm._t("option", function () {
            return [_vm._v("\n                        " + _vm._s(option.caption || option.value || '–') + "\n                    ")];
          }, null, {
            option: option,
            value: _vm.value,
            options: _vm.options,
            filteredOptions: _vm.filteredOptions,
            highlighted: _vm.selected === option
          })], 2);
        }), 1) : _vm._e()];
      },
      proxy: true
    }], null, true)
  }), _vm._v(" "), _c('app-errors-list', {
    staticClass: "app-select__errors",
    attrs: {
      "errors": _vm.errors
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }