var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sign-up"
  }, [_c('keep-alive', [_c('app-height-transition', _vm._b({
    staticClass: "sign-up__form"
  }, 'app-height-transition', _vm.$attrs, false), [_c(_vm.currentStep, {
    key: _vm.currentStep,
    tag: "component",
    staticClass: "sign-up__step",
    attrs: {
      "disable-focus": _vm.disableFocus,
      "hide-title": _vm.hideTitle
    },
    on: {
      "on-back": _vm.goBack
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }