var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.errors.length ? _c('ul', {
    staticClass: "app-errors-list"
  }, _vm._l(_vm.errors, function (error) {
    return _c('app-error', {
      key: error,
      staticClass: "app-errors-list__error"
    }, [_vm._v("\n        " + _vm._s(error) + "\n    ")]);
  }), 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }