var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "$content",
    staticClass: "app-tooltip",
    attrs: {
      "tabindex": "1"
    },
    on: {
      "mouseenter": _vm.onMouseEnter,
      "mouseleave": _vm.onMouseLeave,
      "focus": _vm.onFocus,
      "focusout": _vm.onFocusOut
    }
  }, [_c('div', {
    staticClass: "app-tooltip__handler",
    on: {
      "click": _vm.onClick
    }
  }, [_vm._t("default", null, null, {
    isShowing: _vm.isShowing,
    open: _vm.open,
    toggle: _vm.toggle,
    close: _vm.close
  })], 2), _vm._v(" "), _c('transition', {
    attrs: {
      "name": _vm.name
    },
    on: {
      "enter": _vm.create
    }
  }, [_vm.isShowing ? _c('div', _vm._b({
    ref: "$tooltip",
    class: {
      'app-tooltip__tooltip': true,
      'app-tooltip__tooltip--closable': _vm.closable
    }
  }, 'div', {
    isShowing: _vm.isShowing,
    open: _vm.open,
    toggle: _vm.toggle,
    close: _vm.close
  }, false), [_c('div', {
    staticClass: "app-tooltip__arrow",
    attrs: {
      "data-popper-arrow": ""
    }
  }), _vm._v(" "), _vm._t("tooltip"), _vm._v(" "), _vm.closable ? _c('close-icon', {
    staticClass: "app-tooltip__close",
    on: {
      "click": _vm.close
    }
  }) : _vm._e()], 2) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }