var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "smart-captcha sign-up__captcha",
    attrs: {
      "id": "captcha-container",
      "data-sitekey": "ysc1_ZbeNS8iTFdr5yU53hsvyxrm4ddhPt4bTMmeQ6Sgy40f2c05f",
      "data-hl": "ru"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }