import { Components as Customer } from '@/types/client.customer'
import { toBinaryString, BinaryContent } from '@/composables/files'

export const generalizeLinkImage = (link: string) => `https://www.workle.ru/s3/${link}`

export type ValidateImageCallback = (image: HTMLImageElement) => boolean

export const validateImage = (
    file: Customer.Schemas.BinaryContent,
    callback: ValidateImageCallback
) => new Promise<boolean>((resolve) => {
    const image = new Image()

    image.onload = () => {
        resolve(callback(image))
    }

    image.src = toBinaryString(file)
})

export const binaryContentToImage = (
    binary: BinaryContent
) => new Promise<HTMLImageElement>((resolve) => {
    const image = new Image()

    image.onload = () => resolve(image)

    image.src = toBinaryString(binary)
})

export const validateDimensionsImage = (
    width: number,
    height: number,
    file: Customer.Schemas.BinaryContent
) => validateImage(file, image => image.width === width && image.height === height)
