







































import {
    defineComponent,
    reactive,
    ref,
    onMounted,
    inject,
    Ref
} from '@nuxtjs/composition-api'
import { ValidationComponent } from '@/composables/validation-component'
import { SignUp } from '@/composables/sign-up'

const rules = {
    type: 'object',
    required: [ 'password' ],
    properties: {
        password: {
            type: 'string',
            pattern: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z0-9#$@!%&*?^]{8,}$'
        }
    }
}

const errorMessage = {
    required: 'Заполните обязательные поля',
    properties: {
        password:
            'Пароль должен состоять из 8 символов и содержать минимум 1 заглавную, 1 строчную букву и 1 цифру/символов'
    }
}

export default defineComponent({
    props: {
        hideTitle: {
            type: Boolean,
            default: true
        }
    },
    setup: () => {
        const signUp = inject('signUp') as Ref<SignUp>

        const isLoading = ref(false)
        const form = reactive({
            password: ''
        })
        const validation = ValidationComponent({
            key: 'sign-up-step-password',
            schema: rules,
            errorMessage
        })

        const setPassword = async () => {
            if (isLoading.value) return undefined

            isLoading.value = true

            try {
                await validation.validate(form)

                await signUp.value?.setPassword?.(form.password)

                if (window.ym) {
                    ym(17882743, 'reachGoal', 'registration')
                }
            } catch (error) {
                console.error(error)

                isLoading.value = false
            }
        }

        onMounted(async () => {
            await validation.init()
        })

        return {
            form,
            validation,
            isLoading,
            errors: validation.errors,
            signUp,
            setPassword
        }
    }
})
