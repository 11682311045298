import { render, staticRenderFns } from "./-HeaderCabinetType.vue?vue&type=template&id=793e080e&scoped=true&"
import script from "./-HeaderCabinetType.vue?vue&type=script&lang=ts&"
export * from "./-HeaderCabinetType.vue?vue&type=script&lang=ts&"
import style0 from "./-HeaderCabinetType.vue?vue&type=style&index=0&id=793e080e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "793e080e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppRadio: require('/var/www/app/src/components/ui/AppRadio.vue').default,AppTooltip: require('/var/www/app/src/components/ui/AppTooltip.vue').default})
