

























import { createPropValidator } from '@/composables/prop-validator'
import { PropType, defineComponent } from '@nuxtjs/composition-api'

import IconsSprite from '@/assets/img/sprites/social-icons.svg?inline'

const socials = [ 'vk', 'ok', 'telegram' ] as const
type Social = typeof socials[number]

export default defineComponent({
    props: {
        socials: {
            type: Array as PropType<Social[]>,
            validator: createPropValidator({
                componentName: 'CatalogSocials',
                propertyName: 'socials',
                allowedValues: socials
            }),
            default: () => socials
        }
    },
    setup: () => {
        const socialLinks: Record<Social, string> = {
            vk: 'https://vk.com/iworkle',
            ok: 'https://ok.ru/workle',
            telegram: 'https://t.me/workle_ru'
        }

        return {
            socialLinks,
            IconsSprite
        }
    }
})
