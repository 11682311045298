import { render, staticRenderFns } from "./AppWidgetSkeleton.vue?vue&type=template&id=65053dbc&scoped=true&"
import script from "./AppWidgetSkeleton.vue?vue&type=script&lang=ts&"
export * from "./AppWidgetSkeleton.vue?vue&type=script&lang=ts&"
import style0 from "./AppWidgetSkeleton.vue?vue&type=style&index=0&id=65053dbc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65053dbc",
  null
  
)

export default component.exports