import toCamelCase from 'lodash/camelCase'
import { OpenAPIClientAxios, Document as OpenAPI3Document } from '@fedorif/openapi-client-axios-custom'
import clientIamDefinition from '@/swagger/iam.json'
import { Client as ClientIam } from '@/types/client.iam'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Plugin } from '@nuxt/types'

declare module '@nuxt/types' {
    interface NuxtAppOptions {
        $apiIam: ClientIam
    }

    interface Context {
        $apiIam: ClientIam
    }
}

const AxiosClientIam: Plugin = async ({ $axios }, inject) => {
    const api = new OpenAPIClientAxios({
        transformOperationName: o => toCamelCase(o),
        definition: clientIamDefinition as unknown as OpenAPI3Document,
        axiosInstance: $axios
    })

    const client = await api.getClient<ClientIam>()

    inject('apiIam', client)
}

export default AxiosClientIam
