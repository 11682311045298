





















import { computed, defineComponent, ref } from '@nuxtjs/composition-api'

import AppAside from '@/components/ui/AppAside.vue'

import CatalogBackButton from './-CatalogBackButton.vue'

export default defineComponent({
    components: {
        CatalogBackButton
    },
    props: {
        ...AppAside.props
    },
    setup: () => {
        const $aside = ref<InstanceType<typeof AppAside> | null>(null)
        const open = () => $aside.value?.open()
        const close = () => $aside.value?.close()
        const toggle = () => $aside.value?.toggle()
        const isShowing = computed(() => Boolean($aside.value?.isOpen))

        return {
            $aside,
            open,
            close,
            toggle,
            isShowing
        }
    }
})
