import { render, staticRenderFns } from "./SignUpModal.vue?vue&type=template&id=1fdd91fb&scoped=true&"
import script from "./SignUpModal.vue?vue&type=script&lang=ts&"
export * from "./SignUpModal.vue?vue&type=script&lang=ts&"
import style0 from "./SignUpModal.vue?vue&type=style&index=0&id=1fdd91fb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fdd91fb",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppModal: require('/var/www/app/src/components/ui/AppModal.vue').default})
