



































import {
    defineComponent,
    PropType,
    ref,
    computed,
    onBeforeUnmount
} from '@nuxtjs/composition-api'

import { MountingPortal } from 'portal-vue'

const bothPlacements = [
    'center',
    'stretch'
] as const

const verticalPlacehments = [
    'top',
    'bottom'
] as const

const horizontalPlacements = [
    'top',
    'bottom'
] as const

type VerticalPlacehment = typeof bothPlacements[number] | typeof verticalPlacehments[number]
type HorizontalPlacehment = typeof bothPlacements[number] | typeof horizontalPlacements[number]

export type Placement = `${VerticalPlacehment}-${HorizontalPlacehment}`

export default defineComponent({
    components: {
        MountingPortal
    },
    props: {
        transitionName: {
            type: String,
            default: 'fade'
        },
        placement: {
            type: String as PropType<Placement>,
            default: 'center-stretch'
        },
        name: {
            type: String,
            required: true
        },
        unclosable: {
            type: Boolean,
            default: false
        },
        backgroundUnclosable: {
            type: Boolean,
            default: false
        }
    },
    setup: (props, { emit }) => {
        const lockClass = 'base-modal__body-lock'
        const lock = () => {
            document.body.classList.add(lockClass)
        }
        const unlock = () => {
            document.body.classList.remove(lockClass)
        }

        const isOpen = ref(false)
        const open = () => {
            isOpen.value = true

            lock()
            emit('on-open')
        }
        const close = (fromBackground: boolean = false) => {
            if (fromBackground && (props.unclosable || props.backgroundUnclosable)) {
                return undefined
            }

            isOpen.value = false

            unlock()
            emit('on-close', fromBackground)
        }

        const placementStyle = computed(() => {
            const [ verticalPlacement, horizontalPlacement ] = props.placement.split('-')
            const placementsValues: { [key: string]: 'start' | 'end' | undefined } = {
                left: 'start',
                right: 'end',
                top: 'start',
                bottom: 'end'
            }

            return {
                alignItems: placementsValues[verticalPlacement] || verticalPlacement,
                justifyContent: placementsValues[horizontalPlacement] || horizontalPlacement
            }
        })

        onBeforeUnmount(() => {
            close()
            unlock()
        })

        return {
            isOpen,
            open,
            close,
            placementStyle
        }
    }
})
