var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('transition-group', {
    ref: "$component",
    staticClass: "app-height-transition",
    attrs: {
      "name": _vm.transitionName,
      "tag": _vm.tag
    },
    on: {
      "after-enter": _vm.afterTransition,
      "after-leave": _vm.afterTransition,
      "before-enter": _vm.beforeTransition,
      "before-leave": _vm.beforeTransition,
      "enter": _vm.enter,
      "leave": _vm.leave
    }
  }, [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }