














































import { defineComponent, PropType } from '@nuxtjs/composition-api'

export interface AppRadioItem {
    id: string
    disabled?: boolean
    text: string
}

export default defineComponent({
    props: {
        value: {
            type: [ String, Number, Boolean, Object ] as PropType<any>,
            default: ''
        },
        items: {
            type: Array as PropType<AppRadioItem[]>,
            default: () => []
        },
        errors: {
            type: [ Array, Object ],
            default: () => ([])
        },
        fontSize: {
            type: String,
            default: '12px'
        },
        name: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    setup (_props, { emit }) {
        const onChange = (event: Event) => {
            emit('on-change', event)
        }

        const onInput = (value: string) => {
            emit('input', value)
            emit('on-input', value)
        }

        return {
            onChange,
            onInput
        }
    }
})
