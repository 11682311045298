














import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
    props: {
        tag: {
            type: String,
            default: 'div'
        },
        error: {
            type: Boolean,
            default: false
        },
        active: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
})
