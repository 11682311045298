
















import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
    props: {
        open: {
            type: Boolean,
            default: false
        }
    },
    setup: (props, { emit }) => {
        const onClick = () => {
            if (props.open) {
                return emit('on-close')
            }

            return emit('on-open')
        }

        return {
            onClick
        }
    }
})
