











import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
    props: {
        highlighted: {
            type: Boolean,
            default: false
        }
    }
})
