













import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
    props: {
        vertical: {
            type: Boolean,
            default: false
        },
        newsHorisontal: {
            type: Boolean,
            default: false
        },
        newsVertical: {
            type: Boolean,
            default: false
        }
    }
})
