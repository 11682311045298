

































import { defineComponent, ref, useContext } from '@nuxtjs/composition-api'

import { CatalogItem as CatalogItemInterface } from '@/interfaces/catalog.interface'

import { closeAside } from "@/composables/asides";

import UserIcon from '@/assets/img/user.svg'

export default defineComponent({
    components: {
        UserIcon
    },
    props: {
        aside: {
            type: Boolean,
            default: false
        }
    },
    setup: () => {
        const { $auth } = useContext()
        const logout = () => $auth.logout()

        const items = ref<CatalogItemInterface[]>([
            { name: 'Мой счёт', alias: 'cabinet', url: '/cabinet' },
            { name: 'Приглашения', alias: 'invites', url: '/invites' },
            { name: 'Карьера', alias: 'career', url: '/career' },
            { name: 'Настройки', alias: 'settings', url: '/settings' }
        ])

        return {
            logout,
            items,
            closeAside
        }
    }
})
