var render = function () {
  var _vm$signUp$config;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sign-up__approve-step"
  }, [!_vm.hideTitle ? _c('div', {
    staticClass: "sign-up__title"
  }, [_vm._v("\n        Регистрация "), _vm.signUp.type !== 'customer' ? [_vm._v("\n            партнера\n        ")] : _vm._e(), _vm._v(" на Workle Pro\n    ")], 2) : _vm._e(), _vm._v(" "), _c('p', {
    staticClass: "sign-up__text"
  }, [_vm._v("\n        Введите код из\n        "), _vm.isEmail ? [_vm._v("\n            письма,\n        ")] : [_vm._v("\n            СМС,\n        ")], _vm._v(" который мы отправили на\n        "), _vm.isEmail ? [_vm._v("\n            e-mail\n        ")] : [_vm._v("\n            номер телефона\n        ")], _vm._v(" "), _c('br'), _vm._v(" "), _c('br'), _vm._v(" "), _c('b', [_vm._v(_vm._s(_vm.formattedContact))])], 2), _vm._v(" "), _c('app-button', {
    staticClass: "sign-up__change-contact",
    attrs: {
      "size": "small",
      "flat": "",
      "wide": "",
      "secondary": ""
    },
    on: {
      "on-click": _vm.stepBack
    }
  }, [_vm._v("\n        Изменить\n        "), _vm.isEmail ? [_vm._v("\n            e-mail\n        ")] : [_vm._v("\n            номер телефона\n        ")]], 2), _vm._v(" "), _c('app-input', {
    directives: [{
      name: "validate-field",
      rawName: "v-validate-field.lazy.eager",
      value: {
        validation: _vm.validation
      },
      expression: "{ validation }",
      modifiers: {
        "lazy": true,
        "eager": true
      }
    }],
    staticClass: "sign-up__confirmation-code",
    attrs: {
      "name": "code",
      "placeholder": "Код",
      "wide": "",
      "errors": _vm.errors.code
    },
    on: {
      "on-search": _vm.approve
    },
    model: {
      value: _vm.form.code,
      callback: function ($$v) {
        _vm.$set(_vm.form, "code", $$v);
      },
      expression: "form.code"
    }
  }), _vm._v(" "), _c('app-button', {
    staticClass: "sign-up__confirm-contact",
    attrs: {
      "color": (_vm$signUp$config = _vm.signUp.config) === null || _vm$signUp$config === void 0 ? void 0 : _vm$signUp$config.buttonsColor,
      "wide": "",
      "loading": _vm.isLoading.approve
    },
    on: {
      "on-click": _vm.approve
    }
  }, [_vm._v("\n        Подтвердить\n    ")]), _vm._v(" "), _c('app-button', {
    staticClass: "sign-up__resend",
    attrs: {
      "flat": "",
      "secondary": "",
      "wide": "",
      "disabled": Boolean(_vm.downcountTimer),
      "loading": _vm.isLoading.resend
    },
    on: {
      "on-click": _vm.getCaptcha
    }
  }, [_vm.downcountTimer ? [_vm._v("\n            Повторная отправка через " + _vm._s(_vm.formattedTimer) + "\n        ")] : [_vm._v("\n            Отправить код повторно\n        ")]], 2), _vm._v(" "), _c('captcha-component', {
    ref: "$captcha",
    staticClass: "sign-up__captcha",
    on: {
      "token-received": _vm.resendCode
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }