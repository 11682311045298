var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-tooltip', {
    staticClass: "app-copy",
    attrs: {
      "placement": _vm.placement,
      "visible": _vm.isCopied
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.onClick.apply(null, arguments);
      }
    }
  }, [_vm._t("content", function () {
    return [_c('div', {
      class: {
        'app-copy__content-wrapper': true,
        'app-copy__content-wrapper--no-icon': _vm.noIcon,
        'app-copy__content-wrapper--link': _vm.link
      }
    }, [_vm._t("default", null, null, {
      copy: _vm.copy
    }), _vm._v(" "), !_vm.noIcon ? _vm._t("icon", function () {
      return [_c('copy-icon', {
        staticClass: "app-copy__copy-icon",
        on: {
          "click": function ($event) {
            $event.stopPropagation();
            return _vm.copy.apply(null, arguments);
          }
        }
      })];
    }, null, {
      copy: _vm.copy
    }) : _vm._e()], 2)];
  }, null, {
    copy: _vm.copy
  }), _vm._v(" "), _vm._t("tooltip", function () {
    return [_vm._v("\n        Cкопировано\n    ")];
  }, {
    "slot": "tooltip"
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }