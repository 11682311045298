var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: {
      'app-divider': true,
      'app-divider--vertical': _vm.vertical
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }