






























import { computed, defineComponent, ref } from '@nuxtjs/composition-api'

import EyeOpenedIcon from '@/assets/img/eye-opened.svg'
import EyeClosedIcon from '@/assets/img/eye-closed.svg'

import AppInput from './index.vue'
import AppInputButton from './-AppInputButton.vue'

export default defineComponent({
    components: {
        AppInputButton,
        EyeOpenedIcon,
        EyeClosedIcon
    },
    props: {
        ...AppInput.props
    },
    setup: (_props, { emit }) => {
        const $input = ref<InstanceType<typeof AppInput> | null>(null)
        const focused = computed(() => $input.value?.focused)

        const passwordIsVisible = ref(false)
        const currentType = computed(() => (passwordIsVisible.value ? 'text' : 'password'))

        const toggleVisibility = () => {
            passwordIsVisible.value = !passwordIsVisible.value

            if (passwordIsVisible.value) {
                emit('on-show-password')
            }

            if (!passwordIsVisible.value) {
                emit('on-hide-password')
            }
        }

        return {
            toggleVisibility,
            $input,
            focused,
            currentType,
            passwordIsVisible
        }
    }
})
