var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-modal', _vm._b({
    ref: "$modal",
    attrs: {
      "name": "sign-up-modal"
    }
  }, 'app-modal', _vm.$attrs, false), [_c('sign-up', {
    staticClass: "sign-up-modal__content",
    attrs: {
      "type": _vm.type,
      "initial-state": _vm.initialState
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }