












import { PropType, defineComponent } from '@nuxtjs/composition-api'

import { NavigationItem as NavigationItemInterface } from '@/interfaces/navigation.interface'

import HeaderNavigationItem from './-HeaderNavigationItem.vue'

export default defineComponent({
    components: {
        HeaderNavigationItem
    },
    props: {
        navigationItems: {
            type: Array as PropType<NavigationItemInterface[]>,
            default: () => []
        },
        shadowed: {
            type: Boolean,
            default: false
        }
    }
})
