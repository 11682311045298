var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 18 20",
      "fill": "inherit"
    }
  }, _vm.$listeners), [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M8.877 1c1.197 0 2.323.463 3.17 1.303a4.505 4.505 0 01-.67 6.94 7.969 7.969 0 013.049 1.727c1.338 1.221 2.099 2.777 2.28 4.378.005.04.046.983.044 1.11C15.427 17.528 12.486 19 8.875 19 5.264 19 2.418 17.551 1 16.46a23.65 23.65 0 01.046-1.138c.186-1.572.93-3.098 2.232-4.306a8.005 8.005 0 013.103-1.772 4.322 4.322 0 01-.677-.547 4.507 4.507 0 01-.027-6.365A4.48 4.48 0 018.877 1zm-1.649 9.187A6.856 6.856 0 004.08 11.86c-1.115 1.049-1.902 2.465-1.954 4.014 2.228 1.523 4.978 2.001 6.766 2.001 1.816 0 4.559-.49 6.734-1.992-.156-1.39-.856-3.003-2.002-4.064a6.825 6.825 0 00-3.018-1.612l-.264.862.757 4.064c.094.486-.061.982-.408 1.328l-.122.11-.811.623a1.477 1.477 0 01-1.74.075l-.128-.096-.738-.617a1.477 1.477 0 01-.52-1.266l.024-.159.844-3.985-.27-.96zm2.115 1.445H8.48l-.766 3.726a.392.392 0 00.072.317l.058.062.739.617c.119.1.283.119.419.055l.078-.049.813-.645a.393.393 0 00.149-.286l-.005-.083-.694-3.714zm.208-1.637H8.29l.163.543h.931l.168-.543zm-.674-7.87c-.908 0-1.76.355-2.401 1a3.373 3.373 0 002.4 5.75 3.38 3.38 0 002.377-5.773 3.352 3.352 0 00-2.376-.977z",
      "stroke": "inherit",
      "stroke-width": ".6"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }