





















import { PropType, defineComponent, useContext, computed } from '@nuxtjs/composition-api'

import { Components as Customer } from '@/types/client.customer'
import { createPropValidator } from '@/composables/prop-validator'

const targets = [ '_blank', '_self', '_parent', '_top' ] as const
type Target = typeof targets[number]
type Query = {
    name: string,
    value: string
}

export default defineComponent({
    props: {
        vertical: {
            type: Boolean,
            default: false
        },
        newsHorisontal: {
            type: Boolean,
            default: false
        },
        newsVertical: {
            type: Boolean,
            default: false
        },
        widget: {
            type: Object as PropType<Customer.Schemas.WidgetElement>,
            required: true
        },
        target: {
            type: String as PropType<Target | undefined>,
            default: '_blank',
            validator: createPropValidator({
                componentName: 'AppWidget',
                propertyName: 'target',
                allowedValues: targets,
                canBeUndefined: true
            })
        },
        queries: {
            type: Array as PropType<Query[]>,
            default: () => []
        }
    },
    setup: (props) => {
        const { $apiCustomer } = useContext()

        const onClick = () => {
            try {
                $apiCustomer.widgetsRegisterClick(null, {
                    bannerId: props.widget.bannerId,
                    campaignId: props.widget.campaignId,
                    segmentAlias: props.widget.widgetPlaceAlias || undefined,
                    widgetPlaceId: props.widget.widgetPlaceId
                })
            } catch (error) {
                console.warn(error)
            }
        }

        const widgetUrl = computed(
            () => {
                if (!props.queries.length) return props.widget.url

                const queriesString = props.queries
                    .map(query => `${query.name}=${query.value}`)
                    .join('&')

                return `${props.widget.url}${props.widget?.url?.includes('?') ? '&' : '?'}${queriesString}`
            }
        )

        return {
            widgetUrl,
            onClick
        }
    }
})
