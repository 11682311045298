/* eslint-disable prefer-destructuring */
import { ComputedRef, computed, ref } from '@nuxtjs/composition-api'
import { capitalize } from './utils'

export interface Breakpoint<Alias extends string, Size extends number> {
    alias: Alias
    readonly breakpoint: Size
}

type WatcherName<Alias extends string> = `is${Capitalize<Alias>}`
type WatchersCollection<Alias extends string> = {
    [key in WatcherName<Alias>]: ComputedRef<boolean>
}

export const useDeviceWatcher = <Alias extends string, Size extends number>(
    breakpoints: Breakpoint<Alias, Size>[] = []
) => {
    const currentDevice = ref<Breakpoint<Alias, Size> | undefined>()

    const defineDevice = () => {
        if (!breakpoints.length) return undefined
        if (breakpoints.length === 1) {
            currentDevice.value = breakpoints[0]

            return undefined
        }

        currentDevice.value = breakpoints
            .filter(device => document.documentElement.clientWidth >= device.breakpoint)
            .at(-1)
    }

    const watchers = breakpoints.reduce(
        (
            watchersCollection,
            breakpoint
        ) => {
            const alias = `is${capitalize(breakpoint.alias)}` as WatcherName<Alias>

            watchersCollection[alias] = computed(
                () => (currentDevice.value?.breakpoint || 0) >= breakpoint.breakpoint
            )

            return watchersCollection
        },
        {} as WatchersCollection<Alias>
    )

    const attach = () => {
        window.addEventListener('resize', defineDevice)
        defineDevice()
    }

    const detach = () => {
        window.removeEventListener('resize', defineDevice)
    }

    return {
        defineDevice,
        attach,
        detach,
        currentDevice,
        ...watchers
    }
}
