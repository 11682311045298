var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "viewBox": "0 0 20 19",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, _vm.$listeners), [_c('path', {
    attrs: {
      "d": "M17.2 4.6H2.8A1.8 1.8 0 001 6.4v9a1.8 1.8 0 001.8 1.8h14.4a1.8 1.8 0 001.8-1.8v-9a1.8 1.8 0 00-1.8-1.8z"
    }
  }), _c('path', {
    attrs: {
      "d": "M13.6 17.2V2.8A1.8 1.8 0 0011.8 1H8.2a1.8 1.8 0 00-1.8 1.8v14.4"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }