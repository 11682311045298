
























































































import Inputmask from 'inputmask'

import {
    defineComponent,
    ref,
    computed,
    onMounted,
    nextTick,
    toRefs,
    reactive,
    inject,
    Ref,
    useContext
} from '@nuxtjs/composition-api'
import { ValidationComponent } from '@/composables/validation-component'
import { emailRegExp, phoneRegExp } from '@/composables/regexps'
import {
    SignUp,
    SignUpConfig
} from '@/composables/sign-up'
import { SelectOption } from '@/interfaces/select-option.interface'
import { captchaToken } from '@/composables/sign-up/partner'
import { captchaTokenIam } from '@/composables/sign-up/customer'

import AppInput from '@/components/ui/AppInput/index.vue'
import CaptchaComponent from '@/components/ui/AppCaptchaComponent.vue'

export interface LoginType {
    phone: string | undefined,
    email: string | undefined
    confirmed: boolean
}

const getRules = (config?: SignUpConfig) => {
    const activityTypeRules = {
        activityTypeId: {
            type: 'string',
            minLength: 3
        }
    }

    const rules = {
        type: 'object',
        required: [ 'confirmed' ].concat(config?.requireActivityType ? 'activityTypeId' : []),
        oneOf: [
            { required: [ 'email' ] },
            { required: [ 'phone' ] },
            { required: [ 'unknown' ] }
        ],
        properties: {
            confirmed: { type: 'boolean', enum: [ true ] },
            email: { type: 'string', pattern: emailRegExp.source },
            phone: { type: 'string', pattern: phoneRegExp.source },
            unknown: { type: 'null' },
            ...(config?.requireActivityType ? activityTypeRules : {})
        },
        errorMessage: {
            required: 'Заполните обязательное поле',
            properties: {
                confirmed: 'Согласитесь с условиями',
                email: 'Введите e-mail',
                phone: 'Введите телефон',
                unknown: 'Заполните обязательное поле',
                activityTypeId: 'Выберите направление компании'
            }
        }
    }

    return rules
}

export default defineComponent({
    components: {
        CaptchaComponent
    },
    props: {
        hideTitle: {
            type: Boolean,
            default: true
        },
        disableFocus: {
            type: Boolean,
            default: false
        }
    },
    setup: (props) => {
        const { route, $config } = useContext()
        const signUp = inject('signUp') as Ref<SignUp>
        const isAdminLayout = route.value.fullPath !== '/partners'
        const $captcha = ref<InstanceType<typeof CaptchaComponent> | null>(null)

        const isLoading = reactive({
            activityTypes: true,
            global: false
        })

        const $loginInput = ref<InstanceType<typeof AppInput> | null>(null)

        const validation = ValidationComponent({
            key: 'sign-up-step-registration',
            schema: getRules(signUp.value?.config)
        })

        const confirmed = ref(true)

        const loginEndpoint = `
            ${$config.AUTH_ENDPOINT}/login${route.value.query.ReturnUrl ? `?${(new URLSearchParams({ ReturnUrl: route.value.query.ReturnUrl as string })).toString()}` : ''}
        `

        const activityTypes = ref<SelectOption[]>([])
        const fetchActivityTypes = async () => {
            isLoading.activityTypes = true

            try {
                activityTypes.value = await signUp.value?.fetchActivityTypes?.() || []

                isLoading.activityTypes = false
            } catch (error) {
                console.error(error)
            }
        }

        const allowedIdentifierTypes = computed(
            () => signUp.value?.config?.allowedIdentifierTypes || [ 'Email', 'PhoneNumber' ]
        )
        const loginMask = computed((): Inputmask.Options | null => {
            if (
                allowedIdentifierTypes.value.length === 1
                && allowedIdentifierTypes.value.includes('PhoneNumber')
            ) {
                return {
                    mask: '+7 999 999 99 99',
                    placeholder: '',
                    showMaskOnHover: false
                }
            }

            return null
        })
        const loginInfo = computed(() => {
            const { login = '' } = signUp.value?.state || {}

            if (
                (phoneRegExp.test(login)
                    && allowedIdentifierTypes.value.includes('PhoneNumber'))
                || !allowedIdentifierTypes.value.includes('Email')
            ) {
                return {
                    name: 'phone',
                    text: 'Телефон',
                    type: 'PhoneNumber',
                    errors: validation.errors.value.phone
                }
            }

            if (
                (emailRegExp.test(login)
                    && allowedIdentifierTypes.value.includes('Email'))
                || !allowedIdentifierTypes.value.includes('PhoneNumber')
            ) {
                return {
                    name: 'email',
                    text: 'E-mail',
                    type: 'Email',
                    errors: validation.errors.value.email
                }
            }

            return {
                name: 'unknown',
                text: 'Телефон или e-mail',
                type: 'Unknown',
                errors: validation.errors.value.unknown
            }
        })

        const loginDTO = computed(() => ({
            [loginInfo.value.name]: signUp.value?.state.login,
            activityTypeId: signUp.value?.state.activityTypeId,
            confirmed: confirmed.value
        }))

        const startRegistration = async () => {
            if (isLoading.global) return undefined

            isLoading.global = true

            try {
                await validation.validate(loginDTO.value)

                await signUp.value?.start?.()
            } catch (error) {
                console.error(error)

                const errorTypeText = loginInfo.value.type === 'PhoneNumber'
                    ? 'Телефон'
                    : 'E-Mail'
                validation.setErrors(
                    loginInfo.value.name,
                    [ `данный ${errorTypeText} уже существует` ],
                    true
                )
                $captcha.value?.getCaptcha()

                isLoading.global = false
            }
        }

        onMounted(() => {
            if (isAdminLayout) {
                $captcha.value?.getCaptcha()
            }

            validation.init()
            fetchActivityTypes()

            if (!props.disableFocus) {
                nextTick(() => $loginInput.value?.$refs?.$input?.focus())
            }
        })

        return {
            $captcha,
            ...toRefs(signUp.value.state),
            errors: validation.errors,
            isLoading,
            startRegistration,
            validation,
            isAdminLayout,
            $loginInput,
            confirmed,
            loginInfo,
            captchaToken,
            captchaTokenIam,
            loginDTO,
            loginEndpoint,
            activityTypes,
            loginMask,
            signUp
        }
    }
})
