var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "viewBox": "0 0 14 18",
      "fill-rule": "evenodd",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, _vm.$listeners), [_c('path', {
    attrs: {
      "clip-rule": "evenodd",
      "d": "M5.5.75A2.25 2.25 0 003.25 3v1.5H2.5A2.25 2.25 0 00.25 6.75V15a2.25 2.25 0 002.25 2.25h6A2.25 2.25 0 0010.75 15v-.75h.75A2.25 2.25 0 0013.75 12V5.431a2.25 2.25 0 00-.677-1.608L10.587 1.39A2.25 2.25 0 009.014.75H5.5zm3.75 13.5V15a.75.75 0 01-.75.75h-6a.75.75 0 01-.75-.75V6.75A.75.75 0 012.5 6h.75v6a2.25 2.25 0 002.25 2.25h3.75zm-4.5-9V12a.75.75 0 00.75.75h6a.75.75 0 00.75-.75V5.432a.749.749 0 00-.226-.537L9.538 2.464a.75.75 0 00-.524-.214H5.5a.75.75 0 00-.75.75v2.25z"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }