























































































import {
    defineComponent,
    reactive,
    computed,
    onMounted,
    toRefs,
    inject,
    Ref,
    ref,
} from '@nuxtjs/composition-api'
import { ValidationComponent } from '@/composables/validation-component'
import { formatPhone } from '@/composables/phone'
import { AxiosError } from 'axios'
import { defineLoginType, SignUp, signUpKey } from '@/composables/sign-up'
import { useDowncountTimer } from '@/composables/downcount-timer'
import { captchaToken } from '@/composables/sign-up/partner'
import { captchaTokenIam } from '@/composables/sign-up/customer'
import CaptchaComponent from '@/components/ui/AppCaptchaComponent.vue'

const rules = {
    type: 'object',
    required: [ 'code' ],
    properties: {
        code: { type: 'string', minLength: 1 }
    }
}

const errorMessage = {
    required: 'Укажите код',
    properties: { code: 'Укажите код' }
}

export default defineComponent({
    components: {
        CaptchaComponent
    },
    props: {
        hideTitle: {
            type: Boolean,
            default: true
        }
    },
    setup: (_props, { emit }) => {
        const signUp = inject('signUp') as Ref<SignUp>
        const $captcha = ref<InstanceType<typeof CaptchaComponent> | null>(null)

        const isLoading = reactive({
            resend: false,
            approve: false
        })
        const form = reactive({
            code: ''
        })
        const validation = ValidationComponent({
            key: 'sign-up-step-approve',
            schema: rules,
            errorMessage
        })

        const stepBack = () => emit('on-back')

        const isEmail = computed(
            () => defineLoginType(signUp.value?.state.login || '') === 'Email'
        )

        const approve = async () => {
            if (
                isLoading.approve
                || !signUp.value?.state.signUpId
            ) {
                return undefined
            }

            isLoading.approve = true

            try {
                await validation.validate(form)

                await signUp.value.approve?.(form.code)
            } catch (error) {
                console.error(error)

                const typedError = error as AxiosError

                if (typedError?.response?.status === 500) {
                    validation.setErrors('code', [ 'Неверный код' ])
                }

                isLoading.approve = false
            }
        }

        const formattedContact = computed(
            () => (isEmail.value
                ? signUp.value?.state.login
                : formatPhone(signUp.value?.state.login || ''))
        )

        const {
            formatted: formattedTimer,
            set: setDowncount,
            timer: downcountTimer
        } = useDowncountTimer()
        const resendCode = async () => {
            if (downcountTimer.value || !signUp.value?.start) return undefined

            isLoading.resend = true

            try {
                await signUp.value.start()

                setDowncount(30000)
            } catch (error) {
                console.error(error)
            }

            isLoading.resend = false
        }

        const getCaptcha = () => {
            $captcha.value?.getCaptcha()
        }

        onMounted(async () => {
            await validation.init()
        })

        return {
            $captcha,
            ...toRefs(signUp.value?.state || {}),
            form,
            validation,
            isLoading,
            errors: validation.errors,
            captchaToken,
            captchaTokenIam,
            approve,
            getCaptcha,
            downcountTimer,
            formattedContact,
            isEmail,
            resendCode,
            formattedTimer,
            stepBack,
            signUp
        }
    }
})
