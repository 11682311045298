











import { computed, defineComponent } from '@nuxtjs/composition-api'
import { FormatNumberOptions, formatNumber } from '@/composables/utils'

export default defineComponent({
    props: {
        current: {
            type: Number,
            default: 0
        },
        total: {
            type: Number,
            default: 0
        }
    },
    setup: (props) => {
        const formatParams: FormatNumberOptions = {
            trimFracts: true,
            groupSeparator: '&nbsp;'
        }

        const formattedCurrent = computed(() => formatNumber(props.current, formatParams))
        const formattedTotal = computed(() => formatNumber(props.current, formatParams))

        return {
            formattedCurrent,
            formattedTotal
        }
    }
})
