var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "catalog-socials"
  }, [_c('div', {
    staticClass: "catalog-socials__title"
  }, [_vm._v("\n        Мы в соцсетях\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "catalog-socials__list"
  }, _vm._l(_vm.socials, function (social) {
    return _c('a', {
      key: social,
      staticClass: "catalog-socials__link",
      attrs: {
        "href": _vm.socialLinks[social]
      }
    }, [_c('svg', {
      class: ['catalog-socials__icon', ("catalog-socials__icon--" + social)]
    }, [_c('use', {
      attrs: {
        "href": ((_vm.IconsSprite) + "#" + social)
      }
    })])]);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }