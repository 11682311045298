var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "header-unread-button",
    on: {
      "click": function ($event) {
        return _vm.$emit('on-click');
      }
    }
  }, [_c('div', {
    staticClass: "header-unread-button__content"
  }, [_vm._t("default")], 2), _vm._v(" "), _vm.unreadCount ? _c('div', {
    class: {
      'header-unread-button__unread-count': true,
      'header-unread-button__unread-count--minimal': _vm.minimal
    }
  }, [_vm._v("\n        " + _vm._s(_vm.unreadCount) + "\n    ")]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }