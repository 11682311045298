











import { defineComponent } from '@nuxtjs/composition-api'

import BellIcon from '@/assets/img/bell.svg'

import HeaderUnreadButton from './-HeaderUnreadButton.vue'

export default defineComponent({
    components: {
        HeaderUnreadButton,
        BellIcon
    },
    props: {
        ...HeaderUnreadButton.props
    }
})
