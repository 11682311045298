var render = function () {
  var _class;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.innerComponent, _vm._b({
    tag: "component",
    class: (_class = {
      'app-button': true
    }, _class[("app-button--" + (_vm.size))] = true, _class[("app-button--" + (_vm.color))] = true, _class['app-button--flat'] = _vm.flat && !_vm.active, _class['app-button--secondary'] = _vm.secondary && !_vm.active, _class['app-button--wide'] = _vm.wide, _class['app-button--loading'] = _vm.loading, _class['app-button--disabled'] = _vm.disabled, _class['app-button--gradient'] = _vm.gradient, _class['app-button--active'] = _vm.active, _class['app-button--narrow'] = _vm.narrow, _class),
    attrs: {
      "href": _vm.href,
      "to": _vm.to
    },
    on: {
      "click": _vm.onClick
    }
  }, 'component', _vm.$attrs, false), [_c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.loading ? _c('loading-icon', {
    staticClass: "app-button__spinner"
  }) : _vm._e()], 1), _vm._v(" "), _c('div', {
    class: {
      'app-button__content': true,
      'app-button__content--hidden': _vm.loading
    }
  }, [_vm._t("default", function () {
    return [_vm._v("\n            " + _vm._s(_vm.text) + "\n        ")];
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }