var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "base-spoiler"
  }, [_c('div', {
    staticClass: "base-spoiler__head-wrapper"
  }, [_vm._t("header", null, null, {
    isOpen: _vm.isOpen,
    toggle: _vm.toggle,
    close: _vm.close,
    open: _vm.open
  })], 2), _vm._v(" "), _c('app-height-transition', {
    staticClass: "base-spoiler__content-wrapper"
  }, [_vm.isOpen ? _c('div', {
    key: "content",
    staticClass: "base-spoiler__content"
  }, [_vm._t("content", null, null, {
    isOpen: _vm.isOpen,
    toggle: _vm.toggle,
    close: _vm.close,
    open: _vm.open
  })], 2) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }