var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "publication"
  }, [_c('the-guest-header', {
    staticClass: "publication__header"
  }), _vm._v(" "), _c('client-only', [_c('nuxt')], 1), _vm._v(" "), _c('the-footer', {
    staticClass: "publication__footer"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }