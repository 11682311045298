import { render, staticRenderFns } from "./customer.vue?vue&type=template&id=0b95aa10&"
import script from "./customer.vue?vue&type=script&lang=ts&"
export * from "./customer.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheCatalog: require('/var/www/app/src/components/customer/TheCatalog/index.vue').default,TheMobileProfile: require('/var/www/app/src/components/customer/TheMobileProfile.vue').default})
