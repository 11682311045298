import http from 'axios'
import { set } from '@nuxtjs/composition-api'

import { Components as Customer } from '@/types/client.customer'

import { MIMEType } from '@/composables/mime-types'
import { generalizeLinkImage } from '@/composables/images'

export type BinaryContent = Customer.Schemas.BinaryContent

export interface FileWrapper {
    file: File
    timestamp: number
    id: string
    uploadedSize: number
    size: number
    error: any | null
    uploadResult?: any
    uploader: Promise<any> | null
}

export type SetUploadedSizeFunction = (uploaded: number, total: number) => void
export type UploadFunction = (fileWrapper: FileWrapper, setUploadedSize: SetUploadedSizeFunction) => Promise<any> | any

export const toBinaryContent = (
    file: File | null | undefined,
    setProgressFunction?: SetUploadedSizeFunction
) =>
    new Promise<BinaryContent>((resolve) => {
        const empty = {
            content: undefined,
            contentType: undefined
        }

        if (!file) {
            return resolve(empty)
        }

        const reader = new FileReader()

        if (setProgressFunction) {
            reader.onprogress = event => setProgressFunction(event.loaded, event.total)
        }

        reader.onloadend = () => {
            const { result } = reader

            if (typeof result !== 'string') {
                return resolve(empty)
            }

            const content = result.split(',')[1]
            const contentType = file.type

            resolve({ content, contentType })
        }

        reader.readAsDataURL(file)
    })

export const getUploadResult = (wrappers: FileWrapper | FileWrapper[] | null | undefined) => {
    if (Array.isArray(wrappers)) {
        return wrappers.map(wrapper => wrapper?.uploadResult || null)
    }

    return wrappers?.uploadResult || null
}

export interface ToFileOptions {
    binaryContent: BinaryContent
    fileName?: string
    type?: MIMEType
    lastModified?: number
}
export const toFile = (options: ToFileOptions) => {
    const { binaryContent, fileName = 'File', type, lastModified = 0 } = options
    const { content, contentType } = binaryContent

    if (!content || !contentType) {
        return undefined
    }

    const decodedData = window.atob(content)
    const uInt8Array = new Uint8Array(decodedData.length)

    uInt8Array.forEach((_item, index) => {
        uInt8Array[index] = decodedData.charCodeAt(index)
    })

    return new File([ uInt8Array ], fileName, { type: type || contentType, lastModified })
}

export const getImageEmpty = (): BinaryContent => {
    return {
        content:
            'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
        contentType: 'image/png'
    }
}

export const toBinaryString = (binary: BinaryContent) => {
    return `data:${binary.contentType};base64,${binary.content}`
}

export const onImageUpdate = (files: FileList, form: any, property: string) => {
    if (!files.length) return undefined

    const [ file ] = Array.from(files)
    const reader = new FileReader()

    reader.onloadend = () => {
        if (reader.result === null) return undefined

        const content = (reader.result as string).split(',')[1]
        const contentType = file.type
        const binaryContent: BinaryContent = { content, contentType }

        if (Array.isArray(form)) {
            form[form.length] = binaryContent

            return undefined
        }

        // FIX in vue3
        set(form, property, binaryContent)
    }

    reader.readAsDataURL(file)
}

export const toFileWrapper = (fileData: File | any, fileNumber = ''): FileWrapper => {
    const file = fileData instanceof File
        ? fileData
        : new File([ '' ], `Файл${fileNumber ? ' ' + fileNumber : fileNumber}`)

    const timestamp = new Date().getTime()
    const uploadResult = file

    return {
        error: null,
        file,
        id: `${file.name} (${timestamp})`,
        size: file.size,
        timestamp,
        uploadedSize: file.size,
        uploader: null,
        uploadResult
    }
}

export const linkToBinaryContent = async (link: string) => {
    const image = await http.get(generalizeLinkImage(link), {
        responseType: 'blob'
    })

    return toBinaryContent(image.data)
}
