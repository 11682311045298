import toCamelCase from 'lodash/camelCase'
import { OpenAPIClientAxios, Document as OpenAPI3Document } from '@fedorif/openapi-client-axios-custom'
import clientGuestDefinition from '@/swagger/guest.json'
import { Client as ClientGuest } from '@/types/client.guest'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Plugin } from '@nuxt/types'

declare module '@nuxt/types' {
    interface NuxtAppOptions {
        $apiGuest: ClientGuest
    }

    interface Context {
        $apiGuest: ClientGuest
    }
}

const AxiosClientGuest: Plugin = async ({ $axios }, inject) => {
    const api = new OpenAPIClientAxios({
        transformOperationName: o => toCamelCase(o),
        definition: clientGuestDefinition as unknown as OpenAPI3Document,
        axiosInstance: $axios
    })

    const client = await api.getClient<ClientGuest>()

    inject('apiGuest', client)
}

export default AxiosClientGuest
