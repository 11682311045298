














































import {
    computed,
    defineComponent,
    useContext,
    useRoute,
    useRouter
} from '@nuxtjs/composition-api'

import PersonIcon from '@/assets/img/profiles/person.svg'
import IndividualEntrepreneurIcon from '@/assets/img/profiles/individual-entrepreneur.svg'
import LegalEntityIcon from '@/assets/img/profiles/legal-entity.svg'
import ChevronIcon from '@/assets/img/chevron.svg'
import HeaderBalance from "@/components/pages/TheHeader/-HeaderBalance.vue";

import {
    selectedCabinetId,
    selectedCabinetName,
    selectedCabinetValue,
    cabinetTypesOptions,
    changeCurrentCabinet
} from '@/composables/cabinet-type'

export default defineComponent({
    components: {
        HeaderBalance,
        IndividualEntrepreneurIcon,
        LegalEntityIcon,
        PersonIcon,
        ChevronIcon
    },
    setup: () => {
        const context = useContext()
        const route = useRoute()
        const router = useRouter()

        const changeCurrentCabinetId = async (cabinetId: string) => {
            await changeCurrentCabinet(cabinetId, context)

            if (route.value.fullPath.includes('/p/')) {
                router.go(0)
            }
        }

        const icon = computed(() => ({
            NaturalPerson: PersonIcon,
            IndividualEntrepreneur: IndividualEntrepreneurIcon,
            LegalEntity: LegalEntityIcon,
            SelfEmployed: PersonIcon
        }[selectedCabinetValue.value as string]))

        return {
            cabinetTypesOptions,
            selectedCabinetValue,
            selectedCabinetId,
            selectedCabinetName,
            icon,
            changeCurrentCabinetId
        }
    }
})
