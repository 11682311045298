




















import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
    props: {
        unreadCount: {
            type: Number,
            default: 0
        },
        minimal: {
            type: Boolean,
            default: false
        }
    }
})
