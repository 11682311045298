var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    staticClass: "app-error"
  }, [_c('alert-circle-icon', {
    staticClass: "app-error__icon"
  }), _vm._v(" "), _c('div', {
    staticClass: "app-error__content"
  }, [_vm._t("default")], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }