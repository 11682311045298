import { render, staticRenderFns } from "./-SignUpStepRegistration.vue?vue&type=template&id=aae33426&scoped=true&"
import script from "./-SignUpStepRegistration.vue?vue&type=script&lang=ts&"
export * from "./-SignUpStepRegistration.vue?vue&type=script&lang=ts&"
import style0 from "./-SignUpStepRegistration.vue?vue&type=style&index=0&id=aae33426&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aae33426",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppInput: require('/var/www/app/src/components/ui/AppInput/index.vue').default,AppSelect: require('/var/www/app/src/components/ui/AppSelect/index.vue').default,AppCheckbox: require('/var/www/app/src/components/ui/AppCheckbox.vue').default,AppButton: require('/var/www/app/src/components/ui/AppButton.vue').default})
