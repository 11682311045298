var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "catalog-mobile-navigation"
  }, _vm._l(_vm.navigation, function (link) {
    return _c('catalog-item', _vm._g(_vm._b({
      staticClass: "catalog-profile-section",
      attrs: {
        "aside": _vm.aside,
        "item": link
      },
      nativeOn: {
        "click": function ($event) {
          return _vm.toggleAside(link);
        }
      },
      scopedSlots: _vm._u([link.nested ? {
        key: "content",
        fn: function () {
          return _vm._l(link.nested, function (nestedItem) {
            return _c('catalog-item', {
              key: nestedItem.alias,
              attrs: {
                "nested": "",
                "item": nestedItem,
                "aside": _vm.aside,
                "placement": _vm.$attrs.placement
              },
              nativeOn: {
                "click": function ($event) {
                  return _vm.toggleAside(nestedItem);
                }
              }
            });
          });
        },
        proxy: true
      } : null], null, true)
    }, 'catalog-item', _vm.$attrs, false), _vm.$listeners), [_vm._v("\n        " + _vm._s(link.name) + "\n        ")]);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }