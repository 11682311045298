var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.tag, {
    tag: "component",
    class: {
      'app-input-frame': true,
      'app-input-frame--disabled': _vm.disabled,
      'app-input-frame--active': _vm.active,
      'app-input-frame--error': _vm.error
    }
  }, [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }