


















































import {
    PropType, computed, defineComponent, ref
} from '@nuxtjs/composition-api'

import { NavigationItem as NavigationItemInterface, isNavigationItem } from '@/interfaces/navigation.interface'

import { createPropValidator } from '@/composables/prop-validator'

export default defineComponent({
    name: 'HeaderNavigationItem',
    props: {
        item: {
            type: Object as PropType<NavigationItemInterface | null>,
            default: null,
            validator: createPropValidator({
                componentName: 'NavigationItem',
                propertyName: 'item',
                validator: value => !value || isNavigationItem(value)
            })
        },
        nested: {
            type: Boolean,
            default: false
        },
        shadowed: {
            type: Boolean,
            defalt: false
        }
    },
    setup: (props) => {
        const isOpen = ref(false)
        const timeoutId = ref(null)

        const handleMouseEnter = () => {
            clearTimeout(timeoutId.value)
            isOpen.value = true
        }

        const handleMouseLeave = () => {
            timeoutId.value = setTimeout(() => {
                isOpen.value = false
            }, 300)
        }

        const itemComponentType = computed(() => {
            const { url, nested } = props.item || {}

            if (nested?.length && !url?.trim()) {
                return 'button'
            }

            if (/^http(s?):\/\/.*/.test(url!)) {
                return 'a'
            }

            return 'nuxt-link'
        })

        return {
            isOpen,
            itemComponentType,
            handleMouseEnter,
            handleMouseLeave
        }
    }
})
