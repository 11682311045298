var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "app-radio"
  }, [_vm._l(_vm.items, function (item) {
    return _c('label', {
      key: item.id,
      class: {
        'app-radio__item': true,
        'app-radio__item--disabled': _vm.disabled
      }
    }, [_c('input', {
      class: {
        'app-radio__core': true,
        'app-radio__core--error': _vm.errors.length
      },
      attrs: {
        "type": "radio",
        "name": _vm.name,
        "disabled": item.disabled || _vm.disabled
      },
      domProps: {
        "checked": item.id === _vm.value
      },
      on: {
        "change": _vm.onChange,
        "input": function ($event) {
          return _vm.onInput(item.id);
        }
      }
    }), _vm._v(" "), _c('div', {
      staticClass: "app-radio__label"
    }), _vm._v(" "), _c('span', {
      staticClass: "app-radio__item-text",
      style: {
        fontSize: _vm.fontSize
      }
    }, [_vm._t("default", function () {
      return [_vm._v("\n                " + _vm._s(item.text) + "\n            ")];
    }, null, item)], 2)]);
  }), _vm._v(" "), _vm.errors.length ? _c('div', {
    staticClass: "app-radio__errors"
  }, _vm._l(_vm.errors, function (error, index) {
    return _c('div', {
      key: index,
      staticClass: "app-radio__error",
      domProps: {
        "innerHTML": _vm._s(error)
      }
    });
  }), 0) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }