import { render, staticRenderFns } from "./AppErrorsList.vue?vue&type=template&id=2372f8b0&scoped=true&"
import script from "./AppErrorsList.vue?vue&type=script&lang=ts&"
export * from "./AppErrorsList.vue?vue&type=script&lang=ts&"
import style0 from "./AppErrorsList.vue?vue&type=style&index=0&id=2372f8b0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2372f8b0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppError: require('/var/www/app/src/components/ui/AppError/index.vue').default})
