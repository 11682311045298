var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('catalog-item', _vm._g(_vm._b({
    staticClass: "catalog-profile-section",
    attrs: {
      "aside": _vm.aside
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function () {
        return [_c('user-icon', {
          staticClass: "catalog-profile-section__user-icon"
        })];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_vm._l(_vm.items, function (nestedItem) {
          return _c('catalog-item', {
            key: nestedItem.alias,
            attrs: {
              "nested": "",
              "item": nestedItem,
              "aside": _vm.aside,
              "placement": _vm.$attrs.placement
            },
            nativeOn: {
              "click": function ($event) {
                return _vm.closeAside.apply(null, arguments);
              }
            }
          });
        }), _vm._v(" "), _c('catalog-item', {
          attrs: {
            "nested": "",
            "aside": _vm.aside,
            "placement": _vm.$attrs.placement
          },
          on: {
            "on-click": _vm.logout
          }
        }, [_vm._v("\n            Выйти\n        ")])];
      },
      proxy: true
    }])
  }, 'catalog-item', _vm.$attrs, false), _vm.$listeners), [_vm._v("\n    Личный профиль\n    ")]);
}
var staticRenderFns = []

export { render, staticRenderFns }