var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nav', {
    staticClass: "header-navigation"
  }, _vm._l(_vm.navigationItems, function (item) {
    return _c('header-navigation-item', {
      key: item.alias,
      staticClass: "header-navigation__item",
      attrs: {
        "item": item,
        "shadowed": _vm.shadowed
      }
    });
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }