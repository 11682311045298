












import {
    PropType,
    computed,
    defineComponent,
    ref
} from '@nuxtjs/composition-api'

import { createPropValidator } from '@/composables/prop-validator'

export const placements = [ 'top', 'right', 'bottom', 'left' ] as const
export type Placement = typeof placements[number]

export default defineComponent({
    props: {
        isOpen: {
            type: Boolean,
            default: false
        },
        placement: {
            type: String as PropType<Placement>,
            default: 'top',
            validator: createPropValidator({
                componentName: 'BaseAside',
                propertyName: 'placement',
                allowedValues: placements
            })
        }
    },
    setup: (props, { emit }) => {
        const isOpen = ref(false)
        const open = () => {
            emit('on-open')
            isOpen.value = true
        }
        const close = () => {
            emit('on-close')
            isOpen.value = false
        }
        const toggle = () => {
            emit('on-toggle')

            if (isOpen.value) {
                return close()
            }

            return open()
        }

        const isShowing = computed(() => isOpen.value || props.isOpen)

        return {
            isShowing,
            open,
            close,
            toggle
        }
    }
})
