var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "viewBox": "0 0 60 60",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, _vm.$listeners), [_c('path', {
    attrs: {
      "d": "M8.586 8.586a2 2 0 012.828 0L30 27.172 48.586 8.586a2 2 0 112.828 2.828L32.828 30l18.586 18.586a2 2 0 11-2.828 2.828L30 32.828 11.414 51.414a2 2 0 11-2.828-2.828L27.172 30 8.586 11.414a2 2 0 010-2.828z",
      "fill": "#FF2C2C"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }