




























import { defineComponent, ref, useContext } from '@nuxtjs/composition-api'

import AppModal from '@/components/ui/AppModal.vue'
import { generalizeLinkImage } from '@/composables/images'
import { Components as Customer } from '@/types/client.customer'

export interface PopupData {
    imageKey: string,
    popupId: string,
    redirectUrl: string,
}

export default defineComponent({
    components: { AppModal },
    setup: () => {
        const { $apiCustomer } = useContext()
        const $modal = ref<InstanceType<typeof AppModal> | null>(null)

        const popup = ref<PopupData>({
            imageKey: '',
            popupId: '',
            redirectUrl: ''
        })

        const markEvent = async (type: Customer.Schemas.BannerEventType) => {
            await $apiCustomer.popupRegisterEvent(null, {
                eventType: type,
                popupId: popup.value.popupId
            })
        }

        const open = async (popupData: PopupData) => {
            popup.value = popupData

            $modal.value?.open()

            markEvent('Show')
        }

        return {
            $modal,
            popup,
            generalizeLinkImage,
            markEvent,
            open
        }
    }
})
