var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, _vm.$listeners), [_c('path', {
    attrs: {
      "d": "M20.4 9.84s-3.054 5.04-8.4 5.04c-5.345 0-8.4-5.04-8.4-5.04S6.655 4.8 12 4.8c5.346 0 8.4 5.04 8.4 5.04z",
      "stroke": "inherit",
      "stroke-width": "1.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M12 17.4v1.2M17.76 16.08l.6 1.04M6.24 16.08l-.6 1.04",
      "stroke": "inherit",
      "stroke-width": "2",
      "stroke-linecap": "round"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }