var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "viewBox": "0 0 59 59",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "stroke-linecap": "round",
      "stroke-width": "5",
      "stroke-linejoin": "round",
      "stroke": "#000"
    }
  }, _vm.$listeners), [_c('path', {
    attrs: {
      "d": "M42.133 22.933l-15.877 14.4L20 30.667"
    }
  }), _c('path', {
    attrs: {
      "d": "M56.5 29.5c0 14.912-12.088 27-27 27s-27-12.088-27-27 12.088-27 27-27 27 12.088 27 27z"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }