







export default {
    name: 'CatalogItemSkeleton'
}
