var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    class: {
      'header-burger': true,
      'header-burger--open': _vm.open
    },
    on: {
      "click": _vm.onClick
    }
  }, [_vm._m(0)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header-burger__lines-wrapper"
  }, [_c('div', {
    staticClass: "header-burger__line"
  }), _vm._v(" "), _c('div', {
    staticClass: "header-burger__line"
  }), _vm._v(" "), _c('div', {
    staticClass: "header-burger__line"
  })]);
}]

export { render, staticRenderFns }