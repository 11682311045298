var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "error-page"
  }, [_c('div', {
    staticClass: "errors-block"
  }, [_c('div', {
    staticClass: "errors-block__logo"
  }), _vm._v(" "), _c('div', {
    staticClass: "errors-block__message"
  }, [_c('span', {
    staticClass: "errors-block__message-header"
  }, [_vm._v("Страница не найдена")]), _vm._v(" "), _c('span', {
    staticClass: "errors-block__message-text"
  }, [_vm._v("\n                Она была удалена или в адресе допущена ошибка. Давайте продолжим работу?\n            ")]), _vm._v(" "), _c('div', {
    staticClass: "errors-block__message-btn"
  }, [_c('app-button', {
    staticClass: "reload-btn",
    attrs: {
      "to": "/"
    }
  }, [_vm._v("\n                    Вернуться в админку\n                ")])], 1)])]), _vm._v(" "), _c('div', {
    staticClass: "error-page__colored"
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }