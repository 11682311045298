import { render, staticRenderFns } from "./404.vue?vue&type=template&id=254fa962&scoped=true&"
import script from "./404.vue?vue&type=script&lang=ts&"
export * from "./404.vue?vue&type=script&lang=ts&"
import style0 from "./404.vue?vue&type=style&index=0&id=254fa962&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "254fa962",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppButton: require('/var/www/app/src/components/ui/AppButton.vue').default})
