






































import { defineComponent, ref } from '@nuxtjs/composition-api'

import CrossIcon from '@/assets/img/close.svg'
import MagnifierIcon from '@/assets/img/magnifier.svg'

export default defineComponent({
    components: {
        CrossIcon,
        MagnifierIcon
    },
    props: {
        value: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: 'Найти в каталоге'
        }
    },
    setup: (props, { emit }) => {
        const isFocused = ref(false)
        const onFocus = () => {
            isFocused.value = true
        }
        const onBlur = () => {
            isFocused.value = false
        }

        const onClear = () => {
            emit('on-clear', props.value)
            emit('input', '')

            return undefined
        }

        const onSearch = () => {
            if (!props.value.trim()) {
                return undefined
            }

            emit('on-search', props.value)
            onClear()
        }

        return {
            isFocused,
            onClear,
            onSearch,
            onFocus,
            onBlur
        }
    }
})
