




















import { PropType, defineComponent } from '@nuxtjs/composition-api'
import { Placement } from '@popperjs/core'

import QuestionIcon from '@/assets/img/question-circle.svg'

import AppTooltip from './AppTooltip.vue'

export default defineComponent({
    components: { QuestionIcon },
    props: {
        ...AppTooltip.props,
        placement: {
            type: String as PropType<Placement>,
            default: 'top'
        }
    }
})
