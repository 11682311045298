var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header-search-bar",
    attrs: {
      "tabindex": "1"
    }
  }, [_c('input', _vm._b({
    staticClass: "header-search-bar__core",
    attrs: {
      "placeholder": _vm.placeholder,
      "type": "text"
    },
    domProps: {
      "value": _vm.value
    },
    on: {
      "input": function ($event) {
        var _$event$target;

        return _vm.$emit('input', $event === null || $event === void 0 ? void 0 : (_$event$target = $event.target) === null || _$event$target === void 0 ? void 0 : _$event$target.value);
      },
      "focus": _vm.onFocus,
      "focusout": _vm.onBlur,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.onSearch.apply(null, arguments);
      }
    }
  }, 'input', _vm.$attrs, false)), _vm._v(" "), _vm.value && _vm.isFocused ? _c('button', {
    staticClass: "header-search-bar__button header-search-bar__button--cross",
    on: {
      "mousedown": function ($event) {
        $event.preventDefault();
      },
      "click": _vm.onClear
    }
  }, [_c('cross-icon', {
    staticClass: "header-search-bar__icon header-search-bar__icon--cross"
  })], 1) : _vm._e(), _vm._v(" "), _c('button', {
    staticClass: "header-search-bar__button header-search-bar__button--magnifier",
    on: {
      "mousedown": function ($event) {
        $event.preventDefault();
      },
      "click": _vm.onSearch
    }
  }, [_c('magnifier-icon', {
    staticClass: "header-search-bar__icon header-search-bar__icon--magnifier"
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }