var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer', {
    staticClass: "footer__footer"
  }, [_c('div', {
    staticClass: "footer"
  }, [_c('a', {
    attrs: {
      "href": "http://sk.ru/",
      "target": "_blank"
    }
  }, [_c('skolkovo-logo', {
    staticClass: "footer__logo"
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "footer__description"
  }, [_vm._v("\n            Резидент инновационного центра «Сколково»\n        ")]), _vm._v(" "), _c('div', {
    staticClass: "footer__description footer__description--right"
  }, [_vm._v("\n            © 2011‑" + _vm._s(_vm.currentYear) + "\n            Workle Pro — официальная интернет‑работа\n        ")]), _vm._v(" "), _c('div', {
    staticClass: "footer__description footer__description--bottom"
  }, [_vm._v("\n            Материалы сайта носят информационный характер,\n            не являются публичной офертой и рекламой финансовых,\n            банковских и страховых услуг. Для уточнения всех подробностей\n            предлагаем вам обратиться в соответствующую\n            кредитную/финансовую/страховую организацию\n        ")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }