/* eslint-disable import/no-extraneous-dependencies */
import {
    createSignUp,
    defineLoginType,
    getOriginUrl,
    SignUpConfig,
    SignUpError,
    SignUpSteps
} from '@/composables/sign-up'
import { Components as Partner } from '@/types/client.partner'
import { unifyPhone } from '@/composables/phone'
import { welcomeRedirect } from '@/composables/welcome-redirect'
import { ref } from '@nuxtjs/composition-api'

export const captchaToken = ref()

const oidcPasswordData = ref()

export const usePartnerSignUp = createSignUp('partner', ({ context, state }) => {
    const {
        $apiAuth,
        $apiPartner,
        redirect,
        $auth,
        $config
    } = context

    if ($auth.loggedIn) {
        redirect($config.PARTNER_ENDPOINT)
    }

    const config: SignUpConfig = {
        allowedIdentifierTypes: [ 'PhoneNumber' ],
        buttonsColor: 'green',
        requireActivityType: true,
        offerLink: '/offer'
    }

    const defineStep = async (humanTask?: Partner.Schemas.PartnerSignupHumanTaskOneOf) => {
        if (humanTask?.approveUserIdentifier) {
            state.step = SignUpSteps.Approve

            return undefined
        }

        if (humanTask?.loginHumanTask) {
            state.step = SignUpSteps.Finish

            return undefined
        }

        if (humanTask?.setPasswordHumanTask) {
            state.step = SignUpSteps.Password

            return undefined
        }

        if (humanTask?.partnerDetailsHumanTask) {
            const redirectUrl = await welcomeRedirect(
                $apiPartner.partnerRedirectGetWelcomeRedirect,
                $apiPartner.partnerRedirectUpdateWelcomeRedirect,
                $apiPartner.partnerMembersGetPartnersForUser,
                $apiPartner.leadCostCalculatorCheckPrecalculations
            )

            if (!redirectUrl) {
                return undefined
            }

            redirect(redirectUrl)

            return undefined
        }

        state.step = SignUpSteps.Registration
    }

    const start = async () => {
        const userIdentifierType = defineLoginType(state.login)
        const value = userIdentifierType === 'PhoneNumber'
            ? unifyPhone(state.login)
            : state.login

        try {
            const { data } = await $apiPartner.partnerSignupStartPartnerSignup(null, {
                activityTypeId: state.activityTypeId,
                transitSource: getOriginUrl(),
                userIdentifier: { userIdentifierType, value },
                captchaToken: captchaToken.value
            })

            state.signUpId = String(data.partnerSignupId)

            if ($auth.loggedIn) {
                await $apiPartner.partnerSignupUserLogedIn(state.signUpId)
                localStorage.removeItem('signupId')

                return redirect(302, `${$config.PARTNER_ENDPOINT}/auth/login`)
            }

            localStorage.setItem('signupId', state.signUpId)

            defineStep(data.partnerSignupHumanTask)
        } catch (error) {
            console.error(error)

            throw new SignUpError('Произошла ошибка регистрации. Попробуйте повторить запрос позже.')
        }
    }

    const approve = async (code: string) => {
        const { data } = await $apiPartner.partnerSignupApproveUserIdentifier(state.signUpId, {
            code
        })

        defineStep(data.partnerSignupHumanTask)
    }

    const setPassword = async (password: string) => {
        const { data } = await $apiPartner.partnerSignupSetPassword(state.signUpId, { password })
        oidcPasswordData.value = data
        await login()
        defineStep(data.partnerSignupHumanTask)
    }

    // const login = async () => {
    //     const searchParams = new URLSearchParams(window.location.search)
    //     const loginURL = `${$config.PARTNER_ENDPOINT}/auth/login`
    //
    //     const returnUrl = new URL(window.location.href).searchParams.get('ReturnUrl')
    //     if (returnUrl) {
    //         await finish()
    //     } else {
    //         const frame = searchParams.get('frame')
    //
    //         if (frame !== undefined && frame !== null) {
    //             window.top?.postMessage({ redirectTo: loginURL }, '*')
    //
    //             return undefined
    //         }
    //
    //         window.open(loginURL)
    //     }
    // }

    const login = async () => {
        const partnerSlicedLink = $config.PARTNER_ENDPOINT.slice(8, $config.PARTNER_ENDPOINT.length)
        const authUrl = new URL(`https://auth.ng.workle.ru/login?ReturnUrl=%2Foidc%2Fauthorize%3Fprotocol%3Doauth2%26response_type%3Dcode%26access_type%3D%26client_id%3DPartnerPortal%26redirect_uri%3Dhttps%253A%252F%252F${partnerSlicedLink}%252Fauth%252Fsuccess%26scope%3Dopenid%2520profile%2520offline_access%2520email%26state%3DukojJzF140%26code_challenge_method%3DS256%26code_challenge%3Ddo4cvgg09r8kcHYbLEkxp6N7mlQbIwzMwJU2JTDZtiM%26response_mode%3Dquery`)
        const returnUrl = authUrl.searchParams.get("ReturnUrl");
        // const decodedReturnUrl = decodeURIComponent(returnUrl as string);
        // // Добавляем префикс для корректного парсинга
        // const returnUrlObj = new URL(`https://dummy${decodedReturnUrl}`);
        // const redirectUri = returnUrlObj.searchParams.get("redirect_uri");
        // const decodedRedirectUri = decodeURIComponent(redirectUri as string);
        // const newRedirectUri = `${$config.PARTNER_ENDPOINT}/auth/success`;
        // const encodedNewRedirectUri = encodeURIComponent(newRedirectUri);
        // returnUrlObj.searchParams.set("redirect_uri", encodedNewRedirectUri);
        // const newEncodedReturnUrl = encodeURIComponent(returnUrlObj.pathname + returnUrlObj.search);
        // authUrl.searchParams.set("ReturnUrl", newEncodedReturnUrl);

        const { request } = await $apiAuth.accountsLoginPost({
            UserIdentifier: oidcPasswordData.value.partnerSignupHumanTask.partnerDetailsHumanTask.userIdentifier,
            Password: oidcPasswordData.value.partnerSignupHumanTask.partnerDetailsHumanTask.password,
            ReturnUrl: returnUrl,
        })

        console.log(request)

        window.location = request.responseURL
    }

    const fetchActivityTypes = async () => {
        const { data } = await $apiPartner.partnersPortalGetActivityTypes()

        return data.map(({ id, name }) => ({
            value: String(id),
            caption: String(name)
        }))
    }

    return {
        state,
        config,
        approve,
        start,
        setPassword,
        login,
        fetchActivityTypes
    }
})
