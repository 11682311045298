












































import {
    computed,
    defineComponent,
    onMounted,
    PropType,
    ref,
    watch
} from '@nuxtjs/composition-api'
import { createPopper, Instance, Placement } from '@popperjs/core'

import CloseIcon from '@/assets/img/close.svg'

export default defineComponent({
    components: {
        CloseIcon
    },
    props: {
        isOpen: {
            type: Boolean,
            default: false
        },
        visible: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: 'fade'
        },
        hoverable: {
            type: Boolean,
            default: false
        },
        closable: {
            type: Boolean,
            default: false
        },
        focusable: {
            type: Boolean,
            default: false
        },
        arrowPadding: {
            type: Number,
            default: 13
        },
        offset: {
            type: Number,
            default: 8
        },
        placement: {
            type: String as PropType<Placement>,
            default: 'top',
            validator: (value: string) => {
                const types = [
                    'auto',
                    'auto-start',
                    'auto-end',
                    'top',
                    'top-start',
                    'top-end',
                    'bottom',
                    'bottom-start',
                    'bottom-end',
                    'right',
                    'right-start',
                    'right-end',
                    'left',
                    'left-start',
                    'left-end'
                ]

                if (!types.includes(value)) {
                    console.error(
                        `[Vue warn]: Popper prop "placement" must be any of following "${types.join(
                            ', '
                        )}"`
                    )
                    return false
                }
                return true
            }
        }
    },
    setup: (props, { emit }) => {
        const $content = ref<HTMLElement | null>(null)
        const $tooltip = ref<HTMLElement | null>(null)

        const popper = ref<Instance | null>(null)

        const setOffset = () => {
            if (/start/.test(props.placement)) {
                return [ -props.arrowPadding, props.offset ]
            }

            if (/end/.test(props.placement)) {
                return [ props.arrowPadding, props.offset ]
            }

            return [ 0, props.offset ]
        }

        const create = (tooltip: HTMLElement) => {
            if (!$content.value || !tooltip) return undefined

            popper.value = createPopper($content.value, tooltip, {
                placement: props.placement,
                modifiers: [
                    {
                        name: 'offset',
                        options: { offset: setOffset }
                    },
                    {
                        name: 'arrow',
                        options: { padding: props.arrowPadding }
                    },
                    {
                        name: 'flip',
                        options: {
                            fallbackPlacements: [ 'right', 'left', 'bottom' ]
                        }
                    },
                    {
                        name: 'computeStyles',
                        options: {
                            gpuAcceleration: false,
                            adaptive: false
                        }
                    }
                ]
            })

            popper.value.forceUpdate()
        }

        const mouseIn = ref(false)
        const timeoutId = ref(null)
        const onMouseEnter = () => {
            clearTimeout(timeoutId.value)
            mouseIn.value = true
        }
        const onMouseLeave = () => {
            timeoutId.value = setTimeout(() => {
                mouseIn.value = false
            }, 300)
        }

        const isOpen = ref(false)
        const open = () => {
            isOpen.value = true
            emit('on-open')
        }
        const close = () => {
            isOpen.value = false

            emit('on-close')
        }
        const toggle = () => {
            isOpen.value = !isOpen.value

            emit('on-toggle', isOpen.value)
        }

        const onFocus = () => {
            if (!props.focusable) return undefined

            open()
        }
        const onFocusOut = () => {
            if (!props.focusable) return undefined

            close()
        }
        const onClick = () => {
            if (!props.closable) return undefined

            toggle()
        }

        const isShowing = computed(() => (
            props.hoverable && mouseIn.value)
            || ((props.closable || props.focusable) && isOpen.value)
            || props.visible
            || props.isOpen)

        watch(() => props.placement, () => {
            if (!$tooltip.value) return undefined

            create($tooltip.value)
        })

        onMounted(() => {
            if (!$tooltip.value) return undefined

            create($tooltip.value)
        })

        return {
            $content,
            $tooltip,
            create,
            onMouseEnter,
            onMouseLeave,
            isShowing,
            open,
            close,
            toggle,
            onFocus,
            onFocusOut,
            onClick
        }
    }
})
