import { render, staticRenderFns } from "./-SignUpStepApprove.vue?vue&type=template&id=2c05ddc7&scoped=true&"
import script from "./-SignUpStepApprove.vue?vue&type=script&lang=ts&"
export * from "./-SignUpStepApprove.vue?vue&type=script&lang=ts&"
import style0 from "./-SignUpStepApprove.vue?vue&type=style&index=0&id=2c05ddc7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c05ddc7",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppButton: require('/var/www/app/src/components/ui/AppButton.vue').default,AppInput: require('/var/www/app/src/components/ui/AppInput/index.vue').default})
