




















import {
    defineComponent,
    useContext,
    PropType,
    ref,
    watch,
    provide,
    computed,
    onMounted
} from '@nuxtjs/composition-api'

import {
    SignUp,
    SignUpState,
    SignUpSteps
} from '@/composables/sign-up'

import SignUpStepRegistration from '@/components/ui/TheSignUp/-SignUpStepRegistration.vue'
import SignUpStepApprove from '@/components/ui/TheSignUp/-SignUpStepApprove.vue'
import SignUpStepPassword from '@/components/ui/TheSignUp/-SignUpStepPassword.vue'
import SignUpStepFinish from '@/components/ui/TheSignUp/-SignUpStepFinish.vue'

import { usePartnerSignUp } from '@/composables/sign-up/partner'
import { useCustomerSignUp } from '@/composables/sign-up/customer'
import { useAdminPartnerSignUp } from '@/composables/sign-up/admin-partner'

export type SignUpTypes = ReturnType<
    typeof usePartnerSignUp
    | typeof useCustomerSignUp
    | typeof useAdminPartnerSignUp
>['type']

export default defineComponent({
    auth: false,
    components: {
        SignUpStepRegistration,
        SignUpStepApprove,
        SignUpStepPassword,
        SignUpStepFinish
    },
    props: {
        type: {
            type: String as PropType<SignUpTypes>,
            default: 'customer'
        },
        initialState: {
            type: Object as PropType<Partial<SignUpState>>,
            default: () => ({})
        },
        hideTitle: {
            type: Boolean,
            default: false
        },
        disableFocus: {
            type: Boolean,
            default: false
        }
    },
    setup: (props) => {
        const context = useContext()

        const signUp = ref<SignUp>()
        const currentStep = computed(() => signUp.value?.state?.step)
        const defineSignUp = () => {
            const useSignUp = {
                partner: usePartnerSignUp,
                customer: useCustomerSignUp,
                'admin-partner': useAdminPartnerSignUp
            }[props.type]

            signUp.value = useSignUp(context)
            signUp.value.state = Object.assign(signUp.value.state, props.initialState)
        }
        defineSignUp()
        provide('signUp', signUp)

        let storyStep = false
        const history = ref<SignUpSteps[]>([])
        watch(
            () => signUp.value?.state.step,
            (_new, old) => {
                if (!old) {
                    return undefined
                }

                if (storyStep) {
                    storyStep = false
                    return undefined
                }

                history.value.push(old)
            }
        )
        const goBack = () => {
            const last = history.value.pop()

            if (!last || !signUp.value) {
                return undefined
            }

            storyStep = true
            signUp.value.state.step = last
        }

        onMounted(() => {
            if (props.type === 'partner' && window?.ym && window?.gtag) {
                ym(88574000, 'reachGoal', 'openRegistration')
                gtag('event', 'openForm', { event_category: 'form' })
            }
        })

        return {
            signUp,
            goBack,
            currentStep
        }
    }
})
