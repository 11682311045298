var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "viewBox": "0 0 14 14",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, _vm.$listeners), [_c('path', {
    attrs: {
      "fill": "inherit",
      "d": "M8.6 7L13 2.6c.2-.2.3-.5.3-.8s-.1-.6-.3-.8c-.4-.4-1.2-.4-1.6 0L7 5.4 2.6.9C2.2.5 1.4.5 1 .9c-.3.3-.4.5-.4.8s.1.6.3.8L5.4 7 1 11.4c-.2.2-.3.5-.3.8s.1.6.3.8c.4.4 1.2.4 1.6 0L7 8.6l4.4 4.4c.2.2.5.3.8.3s.6-.1.8-.3c.4-.4.4-1.2 0-1.6L8.6 7z"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }