var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "viewBox": "0 0 12 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, _vm.$listeners), [_c('path', {
    attrs: {
      "fill": "inherit",
      "d": "M11.237 9.989c0 .358-.135.716-.404.989l-8.476 8.59a1.368 1.368 0 01-1.953 0 1.413 1.413 0 010-1.98l7.5-7.6-7.5-7.6a1.412 1.412 0 010-1.978 1.367 1.367 0 011.953 0L10.833 9c.27.273.404.63.404.989z"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }