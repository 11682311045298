import { ComputedRef } from '@nuxtjs/composition-api'
import { Route } from 'vue-router'

export const initLoginAsClaim = (router: ComputedRef<Route>) => {
    const { mode } = router.value.query

    if (mode === 'login-as-claim') {
        localStorage.setItem('isLoginAsClaim', '1')
    }
}

export const checkLoginAsClaim = () => {
    const isMode = localStorage.getItem('isLoginAsClaim')
    return Boolean(Number(isMode))
}

export const removeLoginAsClaim = () => {
    localStorage.removeItem('isLoginAsClaim')
}
