var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "guest-header__header"
  }, [_c('div', {
    staticClass: "guest-header"
  }, [_c('sign-up-modal', {
    ref: "$signUpModal",
    attrs: {
      "type": "customer"
    }
  }), _vm._v(" "), _c('workle-pro-icon', {
    staticClass: "guest-header__icon"
  }), _vm._v(" "), _c('div', {
    staticClass: "guest-header__buttons-block"
  }, [_c('app-button', {
    class: ['guest-header__button--flat', 'guest-header__button'],
    attrs: {
      "color": "green",
      "secondary": "",
      "size": "small",
      "nuxt": "",
      "href": ((_vm.$config.CUSTOMER_ENDPOINT) + "/auth/login")
    }
  }, [_vm._v("\n                Войти\n            ")]), _vm._v(" "), _c('app-button', {
    staticClass: "guest-header__button",
    attrs: {
      "color": "green",
      "size": "small"
    },
    on: {
      "on-click": function ($event) {
        return _vm.signUp();
      }
    }
  }, [_vm._v("\n                Зарегистрироваться\n            ")])], 1), _vm._v(" "), _c('div', {
    staticClass: "guest-header__burger-block"
  }, [_c('header-burger', {
    attrs: {
      "open": _vm.openedAside === 'catalog'
    },
    on: {
      "on-close": _vm.closeAside,
      "on-open": function ($event) {
        return _vm.openAside('catalog');
      }
    }
  })], 1), _vm._v(" "), _c('app-aside', {
    staticClass: "guest-header__aside",
    attrs: {
      "placement": "right",
      "is-open": _vm.openedAside === 'catalog'
    },
    on: {
      "on-close": _vm.closeAside
    }
  }, [_c('div', {
    staticClass: "guest-header__aside-buttons"
  }, [_c('app-button', {
    class: ['guest-header__button--flat', 'guest-header__button'],
    attrs: {
      "color": "green",
      "secondary": "",
      "size": "small",
      "nuxt": "",
      "target": "_blank",
      "href": ((_vm.$config.CUSTOMER_ENDPOINT) + "/auth/login")
    },
    on: {
      "on-click": function ($event) {
        return _vm.closeAside();
      }
    }
  }, [_vm._v("\n                    Войти\n                ")]), _vm._v(" "), _c('app-button', {
    staticClass: "guest-header__button",
    attrs: {
      "color": "green",
      "size": "small"
    },
    on: {
      "on-click": function ($event) {
        _vm.closeAside(), _vm.signUp();
      }
    }
  }, [_vm._v("\n                    Зарегистрироваться\n                ")])], 1)])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }