















































import {
    computed,
    defineComponent,
    PropType,
    ref
} from '@nuxtjs/composition-api'

import BigRedCrossIcon from '@/assets/img/big-red-cross.svg'
import CircledCheckIcon from '@/assets/img/circled-check.svg'
import CircledWarnIcon from '@/assets/img/circled-warn.svg'
import StopWatchIcon from '@/assets/img/stopwatch.svg'

import AppModal from '@/components/ui/AppModal.vue'
import { ResultModalPayload, ResultModalType } from '@/interfaces/result-modal.interface'
import { RawLocation } from 'vue-router'

type GeneralKeys = Exclude<keyof ResultModalPayload, 'onSubmit' | 'onClose'>

export default defineComponent({
    components: {
        AppModal
    },
    props: {
        name: {
            type: String,
            required: true
        },
        title: {
            type: String,
            default: ''
        },
        text: {
            type: String,
            default: ''
        },
        type: {
            type: String as PropType<ResultModalType | null>,
            default: null
        },
        buttonCaption: {
            type: String,
            default: 'Хорошо'
        },
        href: {
            type: String,
            default: null
        },
        to: {
            type: [ String, Object ] as PropType<RawLocation | null | undefined>,
            default: null
        }
    },
    setup: (props, { emit }) => {
        const $modal = ref<InstanceType<typeof AppModal> | null>(null)
        const payload = ref<ResultModalPayload>({})
        const open = (_payload?: ResultModalPayload) => {
            payload.value = _payload || {}

            $modal.value?.open()
        }
        const close = (byBackground: boolean) => {
            if (payload.value.onClose) {
                payload.value.onClose(byBackground)
            }

            $modal.value?.close()
        }

        const getProp = (
            prop: GeneralKeys
        ) => payload.value[prop] || props[prop]

        const generalSettings = computed(() => {
            const fields = [
                'title',
                'text',
                'type',
                'buttonCaption',
                'href',
                'to'
            ] as const

            return fields.reduce((total, key) => {
                const value = getProp(key)

                if (!value) {
                    return total
                }

                total[key] = value

                return total
            }, {} as Record<GeneralKeys, string | RawLocation | null | undefined>)
        })

        const settings = computed(() => {
            const actualSettings = payload.value.type || props.type || 'success'
            const settings = {
                success: {
                    icon: CircledCheckIcon,
                    buttonColor: 'green'
                },
                error: {
                    icon: BigRedCrossIcon,
                    title: 'Произошла ошибка',
                    text: 'Перезагрузите страницу и попробуйте еще раз'
                },
                warning: {
                    icon: CircledWarnIcon
                },
                stopwatch: {
                    icon: StopWatchIcon
                }
            }[actualSettings] || {}

            return Object.assign(
                settings,
                generalSettings.value
            )
        })

        const resetContent = () => {
            payload.value = {
                buttonCaption: undefined,
                text: undefined,
                title: undefined,
                href: undefined,
                onClose: undefined,
                onSubmit: undefined,
                to: undefined,
                type: undefined
            }
        }

        const onSubmit = () => {
            if (payload.value.onSubmit) {
                payload.value.onSubmit()
            }

            emit('on-submit')
        }

        const onClose = (fromBackground: boolean) => {
            if (payload.value.onClose) {
                payload.value.onClose(fromBackground)
            }

            resetContent()

            emit('on-close')
        }

        return {
            open,
            close,
            onSubmit,
            settings,
            $modal,
            onClose,
            payload,
            generalSettings
        }
    }
})
