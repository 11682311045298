




import {
    defineComponent, onMounted, ref
} from '@nuxtjs/composition-api'

import AdminNotFound from '@/components/errors/admin/404.vue'
import CustomerNotFound from '@/components/errors/customer/404.vue'
import GuestNotFound from '@/components/errors/guest/404.vue'
import PartnerNotFound from '@/components/errors/partner/404.vue'

export default defineComponent({
    setup: () => {
        const componentType = {
            admin: AdminNotFound,
            customer: CustomerNotFound,
            guest: GuestNotFound,
            partner: PartnerNotFound
        }

        const bundleType = ref(null)

        onMounted(() => {
            bundleType.value = window.location.host.split('.')[0] as string
        })

        return {
            componentType,
            bundleType
        }
    }
})
