var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._g(_vm._b({
    ref: "$element",
    staticClass: "base-dropdown"
  }, 'div', _vm.$attrs, false), _vm.$listeners), [_vm._t("default", null, null, {
    open: _vm.open,
    close: _vm.close,
    isOpen: _vm.isOpen,
    toggle: _vm.toggle
  }), _vm._v(" "), _c('transition', {
    attrs: {
      "name": _vm.transitionName
    }
  }, [_vm.isOpen && !_vm.disabled ? _c('div', {
    ref: "$content",
    class: {
      'base-dropdown__content': true,
      'base-dropdown__content--stretch': _vm.stretch
    },
    style: _vm.contentStyles
  }, [_vm._t("content", null, null, {
    open: _vm.open,
    close: _vm.close,
    isOpen: _vm.isOpen,
    toggle: _vm.toggle
  })], 2) : _vm._e()])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }