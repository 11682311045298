import { computed, ref, watch } from '@nuxtjs/composition-api'
import moment from 'moment'

export const useDowncountTimer = () => {
    const timer = ref(0)

    let intervalHandler: unknown = null

    const set = (miliseconds: number) => {
        timer.value = miliseconds
        intervalHandler = window.setInterval(() => {
            timer.value -= 1000
        }, 1000)
    }

    const formatted = computed(
        () => moment(timer.value).format('mm:ss')
    )

    watch(timer, (count) => {
        if (!count) {
            window.clearInterval(intervalHandler as number)
        }
    })

    return {
        set,
        formatted,
        timer
    }
}
