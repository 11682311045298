export interface NavigationItem {
    name: string
    alias: string
    url?: string,
    mark?: boolean,
    nested?: NavigationItem[]
}

export const isNavigationItem = (item: any): item is NavigationItem => (
    item
    && typeof item.name === 'string'
    && typeof item.alias === 'string'
    && (!item.url || typeof item.url === 'string')
    && (!item.nested || item.nested.every(isNavigationItem))
)
