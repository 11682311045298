var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    class: {
      'header-search-button': true,
      'header-search-button--open': _vm.open
    },
    on: {
      "click": _vm.onClick
    }
  }, [_c('search-icon', {
    staticClass: "header-search-button__icon"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }