interface Options<T = unknown> {
    canBeNull?: boolean
    canBeUndefined?: boolean
    componentName: string
    propertyName: string
    readonly allowedValues?: ReadonlyArray<T>
    validator?: (value: any) => boolean
}

const defineError = (options: Options) => {
    const {
        componentName,
        propertyName,
        allowedValues = []
    } = options

    const prefix = `<${componentName} /> [WARN]: Property "${propertyName}"`

    if (allowedValues?.length) {
        const allowedString = allowedValues.join(', ')

        return (value: any) => console.error.bind(
            `${prefix} must be one of: ${allowedString}. Now it is "${value}".`
        )
    }

    return (value: any) => console.error(
        `${prefix} has an invalid value: "${value}".`
    )
}

const defineValidation = (options: Options) => {
    const {
        allowedValues = [],
        validator,
        canBeNull = false,
        canBeUndefined = false
    } = options

    return validator || ((value: any) => (
        (allowedValues.length && allowedValues.includes(value))
        || (value === null && canBeNull)
        || (value === undefined && canBeUndefined)
    ))
}

export const createPropValidator = (options: Options) => {
    const validation = defineValidation(options)
    const showError = defineError(options)

    return (value: any) => validation(value) || Boolean(showError(value))
}
