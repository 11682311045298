var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "viewBox": "0 0 79 60",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, _vm.$listeners), [_c('path', {
    attrs: {
      "d": "M59.76 9.998c.462-.927.724-1.97.724-3.075C60.484 3.106 57.37 0 53.541 0c-3.829 0-6.943 3.106-6.943 6.923 0 1.104.261 2.148.724 3.075-11.042 2.774-19.24 12.758-19.24 24.617C28.082 48.612 39.502 60 53.541 60 67.579 60 79 48.612 79 34.615c0-11.859-8.198-21.843-19.24-24.617zm-8.533-3.075a2.314 2.314 0 012.314-2.308 2.314 2.314 0 012.315 2.308 2.314 2.314 0 01-2.315 2.308 2.314 2.314 0 01-2.314-2.308zm4.629 48.333v-2.18a2.311 2.311 0 00-2.315-2.307 2.311 2.311 0 00-2.314 2.308v2.179c-9.64-1.068-17.316-8.72-18.387-18.333h2.185a2.311 2.311 0 002.315-2.308 2.311 2.311 0 00-2.315-2.307H32.84c1.07-9.613 8.746-17.265 18.387-18.333v2.179a2.311 2.311 0 002.314 2.308 2.311 2.311 0 002.315-2.308v-2.179c9.64 1.068 17.315 8.72 18.386 18.333h-2.185a2.311 2.311 0 00-2.315 2.307 2.311 2.311 0 002.315 2.308h2.185c-1.07 9.612-8.746 17.265-18.387 18.333zM21.138 23.077h-9.566a2.311 2.311 0 00-2.314 2.308 2.311 2.311 0 002.314 2.307h9.566a2.311 2.311 0 002.315-2.307 2.311 2.311 0 00-2.315-2.308zM21.139 36.923H6.943a2.311 2.311 0 00-2.314 2.308 2.311 2.311 0 002.314 2.307h14.195a2.311 2.311 0 002.315-2.307 2.311 2.311 0 00-2.314-2.308zM21.139 50.77H2.314A2.311 2.311 0 000 53.076a2.311 2.311 0 002.314 2.308H21.14a2.311 2.311 0 002.314-2.308 2.311 2.311 0 00-2.314-2.308z"
    }
  }), _c('path', {
    attrs: {
      "d": "M62.798 32.308h-6.943v-6.923a2.311 2.311 0 00-2.315-2.308 2.311 2.311 0 00-2.314 2.308v9.23a2.311 2.311 0 002.314 2.308h9.258a2.311 2.311 0 002.314-2.308 2.311 2.311 0 00-2.314-2.307z"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }