var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-modal', _vm._g(_vm._b({
    ref: "$modal",
    attrs: {
      "name": ((_vm.name) + " the-result-modal")
    },
    on: {
      "on-close": _vm.onClose
    }
  }, 'app-modal', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "the-result-modal__body"
  }, [_c(_vm.settings.icon, {
    tag: "component",
    class: ['the-result-modal__icon', ("the-result-modal__icon--" + (_vm.settings.type))]
  }), _vm._v(" "), _c('div', {
    staticClass: "the-result-modal__title"
  }, [_vm._t("title", function () {
    return [_c('span', {
      domProps: {
        "innerHTML": _vm._s(_vm.settings.title)
      }
    })];
  }, null, {
    title: _vm.settings.title
  })], 2), _vm._v(" "), _c('div', {
    staticClass: "the-result-modal__text"
  }, [_vm._t("text", function () {
    return [_c('span', {
      domProps: {
        "innerHTML": _vm._s(_vm.settings.text)
      }
    })];
  }, null, {
    title: _vm.settings.text
  })], 2), _vm._v(" "), _c('app-button', {
    staticClass: "the-result-modal__button",
    attrs: {
      "secondary": "",
      "wide": "",
      "color": _vm.settings.buttonColor,
      "href": _vm.settings.href,
      "to": _vm.settings.to
    },
    on: {
      "on-click": _vm.onSubmit
    }
  }, [_vm._v("\n            " + _vm._s(_vm.settings.buttonCaption) + "\n        ")])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }