










































































import { defineComponent, ref, useContext } from '@nuxtjs/composition-api'

import WorkleProIcon from '@/assets/img/logo-pro-gray.svg'
import SignUpModal from '@/components/ui/TheAuth/SignUpModal.vue'
import HeaderBurger from '@/components/pages/TheHeader/-HeaderBurger.vue'

import { openedAside, openAside, closeAside } from '@/composables/asides'

export default defineComponent({
    components: {
        WorkleProIcon,
        SignUpModal,
        HeaderBurger
    },
    setup: () => {
        const $signUpModal = ref<InstanceType<typeof SignUpModal> | null>(null)
        const { route, redirect, $config } = useContext()

        const signUp = () => {
            redirect(
                302,
                `${$config.AUTH_ENDPOINT}/signup`,
                {
                    // eslint-disable-next-line camelcase
                    reg_source: `${$config.GUEST_ENDPOINT}${route.value.fullPath}`
                }
            )
        }

        return {
            $signUpModal,
            openedAside,
            signUp,
            openAside,
            closeAside

        }
    }
})
