var render = function () {
  var _vm$item, _vm$item$nested, _vm$item2, _vm$item3, _vm$item5, _vm$item6, _vm$item6$nested, _vm$item7, _vm$item7$nested;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: {
      'header-navigation-item': true,
      'header-navigation-item--nested': _vm.nested,
      'header-navigation-item--has-nested': (_vm$item = _vm.item) === null || _vm$item === void 0 ? void 0 : (_vm$item$nested = _vm$item.nested) === null || _vm$item$nested === void 0 ? void 0 : _vm$item$nested.length
    },
    on: {
      "mouseenter": _vm.handleMouseEnter,
      "mouseleave": _vm.handleMouseLeave
    }
  }, [_c(_vm.itemComponentType, {
    tag: "component",
    staticClass: "header-navigation-item__head",
    attrs: {
      "to": (_vm$item2 = _vm.item) === null || _vm$item2 === void 0 ? void 0 : _vm$item2.url,
      "href": (_vm$item3 = _vm.item) === null || _vm$item3 === void 0 ? void 0 : _vm$item3.url,
      "target": _vm.itemComponentType === 'a' && '_blank'
    }
  }, [_c('div', {
    staticClass: "header-navigation-item__name"
  }, [_vm._t("name", function () {
    var _vm$item4;

    return [_vm._v("\n                " + _vm._s((_vm$item4 = _vm.item) === null || _vm$item4 === void 0 ? void 0 : _vm$item4.name) + "\n            ")];
  }, null, _vm.item), _vm._v(" "), (_vm$item5 = _vm.item) !== null && _vm$item5 !== void 0 && _vm$item5.mark ? _c('app-status', {
    staticClass: "header-navigation-item__mark",
    attrs: {
      "status": 'Disabled'
    }
  }) : _vm._e()], 2)]), _vm._v(" "), (_vm$item6 = _vm.item) !== null && _vm$item6 !== void 0 && (_vm$item6$nested = _vm$item6.nested) !== null && _vm$item6$nested !== void 0 && _vm$item6$nested.length ? _c('div', {
    class: {
      'header-navigation-item__nested-content': true,
      'header-navigation-item__nested-content--open': _vm.isOpen && ((_vm$item7 = _vm.item) === null || _vm$item7 === void 0 ? void 0 : (_vm$item7$nested = _vm$item7.nested) === null || _vm$item7$nested === void 0 ? void 0 : _vm$item7$nested.length),
      'header-navigation-item__nested-content--shadowed': _vm.shadowed
    }
  }, _vm._l(_vm.item.nested, function (nestedItem) {
    return _c('header-navigation-item', {
      key: nestedItem.alias,
      staticClass: "header-navigation-item__nested-item",
      attrs: {
        "item": nestedItem,
        "nested": ""
      }
    });
  }), 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }