var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "catalog-back-button",
    on: {
      "click": function ($event) {
        return _vm.$emit('on-click');
      }
    }
  }, [_c('arrow-icon', {
    staticClass: "catalog-back-button__icon"
  }), _vm._v(" "), _vm._t("default", function () {
    return [_vm._v("\n        Назад\n    ")];
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }