






















import {
    computed,
    defineComponent,
    onBeforeUnmount,
    ref,
    watch
} from '@nuxtjs/composition-api'

import BaseAside from './BaseAside.vue'

export default defineComponent({
    props: {
        ...BaseAside.props,
        hideBackground: {
            type: Boolean,
            default: false
        },
        disableBodyLock: {
            type: Boolean,
            default: false
        }
    },
    setup: (props) => {
        const $aside = ref<InstanceType<typeof BaseAside> | null>(null)
        const open = () => $aside.value?.open()
        const close = () => $aside.value?.close()
        const toggle = () => $aside.value?.toggle()
        const isShowing = computed(() => Boolean($aside.value?.isShowing))

        const setScrollLock = (value: boolean) => {
            if (props.disableBodyLock) return undefined

            const className = 'app-aside-sroll-lock'

            if (value) {
                return document.body.classList.add(className)
            }

            return document.body.classList.remove(className)
        }
        watch(() => isShowing.value, value => setScrollLock(Boolean(value)))

        onBeforeUnmount(() => setScrollLock(false))

        return {
            $aside,
            open,
            close,
            toggle,
            isShowing
        }
    }
})
