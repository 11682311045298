var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-tooltip', _vm._g(_vm._b({
    staticClass: "app-hint",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (defaultScope) {
        return [_vm._t("icon", function () {
          return [_c('question-icon', {
            staticClass: "app-hint__icon"
          })];
        }, null, defaultScope)];
      }
    }, {
      key: "tooltip",
      fn: function (tooltipScope) {
        return [_vm._t("default", null, null, tooltipScope)];
      }
    }], null, true)
  }, 'app-tooltip', _vm.$props, false), _vm.$listeners));
}
var staticRenderFns = []

export { render, staticRenderFns }