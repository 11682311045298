interface DebounceOptions {
    immediate?: boolean
}

export const debounce = <T extends Function>(func: T, wait: number, options: DebounceOptions = {}): T => {
    let timeoutId: number
    const isImmediate = options?.immediate || false

    return <any> function (this: any, ...args: any[]) {
        clearTimeout(timeoutId)

        const context = this

        if (isImmediate && timeoutId === undefined) {
            func.apply(context, args)
        }

        timeoutId = window.setTimeout(() => {
            func.apply(context, args)
        }, wait)
    }
}
