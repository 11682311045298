var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._g({
    attrs: {
      "viewBox": "0 0 89 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, _vm.$listeners), [_c('path', {
    attrs: {
      "d": "M21.116 2.051H18.14a.244.244 0 00-.248.13.254.254 0 00-.03.095l-1.99 13.296-3.11-12.026c-.232-.888-.94-1.506-2.002-1.506-1.062 0-1.804.618-2.025 1.506L5.559 15.55 3.612 2.332s-.04-.276-.273-.276H.242c-.298 0-.237.366-.237.366l2.833 15.701c.21 1.169 1.04 1.854 2.445 1.854 1.339 0 2.235-.562 2.534-1.686l2.898-10.62 2.767 10.62C13.78 19.438 14.654 20 16.037 20c1.383 0 2.213-.674 2.468-1.855L21.37 2.366c0-.011.067-.315-.254-.315zm6.477 4.692c-3.784 0-6.174 2.39-6.174 6.606 0 4.182 2.28 6.628 6.108 6.628 3.784 0 6.218-2.446 6.218-6.65 0-4.206-2.346-6.584-6.152-6.584zm-.19 10.806c-1.936 0-2.998-1.459-2.998-4.198 0-2.66.974-4.164 2.932-4.164 1.936 0 3.02 1.47 3.02 4.164 0 2.717-1.017 4.198-2.953 4.198zM42.132 7.225c-2.313 0-3.585 0-5.19.449-1.062.303-1.615.888-1.615 2.034v10.011a.26.26 0 00.165.242.25.25 0 00.1.016c.266 0 2.28-.01 2.557-.01a.254.254 0 00.254-.155.265.265 0 00.022-.104v-9.27c1.107-.315 2.213-.427 3.685-.45a.272.272 0 00.185-.08.28.28 0 00.08-.19V7.484a.237.237 0 00-.062-.187.23.23 0 00-.181-.071zm13.102 12.38l-3.706-8.163 3.34-3.772c.111-.124.134-.439-.176-.439h-3.153a.284.284 0 00-.244.101l-4.304 5.259V2.299c0-.248-.265-.259-.265-.259H44.09s-.232 0-.232.282v17.396a.254.254 0 00.076.19.249.249 0 00.19.07h2.633c.232 0 .243-.27.243-.27v-1.679c-.022-1.396.332-2.015 1.03-2.792l1.017-1.137 2.645 5.708a.263.263 0 00.22.158h3.099c.365.023.221-.36.221-.36z",
      "fill": "silver"
    }
  }), _c('path', {
    attrs: {
      "d": "M61.297 2.046h-2.555a.3.3 0 00-.2.077.308.308 0 00-.1.192v17.38a.27.27 0 00.07.195.26.26 0 00.185.086h2.634a.224.224 0 00.177-.066.23.23 0 00.066-.18V2.337a.267.267 0 00-.07-.212.259.259 0 00-.207-.08zm13.609 11.036c0-4.313-2.246-6.34-5.81-6.34-3.263 0-5.996 2.049-5.996 6.677 0 4.392 2.467 6.555 6.306 6.555 1.162 0 3.995-.373 4.847-.485.144-.022.232-.112.232-.305v-2.027c0-.156-.1-.303-.266-.28-.895.124-3.628.383-4.514.383-1.948 0-3.353-.88-3.519-2.884h7.435c.942-.022 1.285-.371 1.285-1.294zm-8.741-1.094c.12-1.935 1.15-2.824 2.832-2.824 1.991 0 2.844 1.102 2.844 2.824h-5.676zM76.435 6.243V.888c0-.37.162-.567.527-.677a4.547 4.547 0 011.35-.204c1.28 0 2.023.803 2.023 2.18 0 1.392-.65 2.203-1.907 2.203a3.104 3.104 0 01-.93-.174v2.027h-1.063zm2.846-4.041c0-.81-.303-1.314-1.032-1.314-.253.006-.505.04-.752.102v2.376c.434.118.62.142.791.142.714 0 .993-.472.993-1.306zM83.503.911a4.981 4.981 0 00-1.358.15v3.305H81.09V.904c0-.386.187-.583.543-.684.559-.158 1.04-.205 1.87-.205V.91zM86 4.413c-1.286 0-2.07-.802-2.07-2.202C83.93.794 84.752 0 86.024 0c1.28 0 2.07.794 2.07 2.21 0 1.41-.821 2.203-2.093 2.203zM86 .81c-.658 0-.984.504-.984 1.4 0 .922.357 1.401 1.008 1.401.652 0 .993-.488.993-1.4 0-.905-.364-1.4-1.016-1.4z",
      "fill": "#049FFF"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }