






















import {
    computed,
    defineComponent,
    nextTick,
    onMounted,
    ref
} from '@nuxtjs/composition-api'

export default defineComponent({
    props: {
        image: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        }
    },
    setup: (props) => {
        const $wrapper = ref<HTMLDivElement | null>(null)
        const width = ref(0)

        const initials = computed(() => {
            const nameParts = props.name.replace(/ +/g, ' ').split(' ')

            if (!nameParts.length) {
                return 'ПW'
            }

            if (nameParts.length === 3) {
                return `${nameParts[1][0]}${nameParts[0][0]}`
            }

            if (nameParts.length === 2) {
                return `${nameParts[0][0]}${nameParts[1][0]}`
            }

            return nameParts[0][0]
        })

        const initialsStyle = computed(() => {
            const defaultColor = '#78D9FB'
            const colors = [
                '#FE4F4F',
                '#F77B55',
                '#FEA34F',
                '#EECF2A',
                '#14D627',
                '#14D684',
                '#1ED8E3',
                '#874FFE',
                '#C861F9',
                '#FA78EF',
                '#54ABFC',
                defaultColor
            ]
            const letterCode = initials.value.toUpperCase().charCodeAt(0) - 1039
            const index = Math.floor(letterCode * (colors.length / 32))

            return {
                backgroundColor: colors[index] || defaultColor,
                fontSize: `${(width.value / 100) * 36}px`
            }
        })

        onMounted(() => {
            nextTick(() => {
                width.value = $wrapper.value?.offsetWidth || 56
            })
        })

        return {
            $wrapper,
            initialsStyle,
            initials,
            width
        }
    }
})
