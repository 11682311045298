var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    class: {
      'app-input-button': true,
      'app-input-button--active': _vm.active,
      'app-input-button--error': _vm.error,
      'app-input-button--disabled': _vm.disabled
    },
    attrs: {
      "disabled": _vm.disabled
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('on-click');
      }
    }
  }, [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }