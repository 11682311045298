









import { defineComponent } from '@nuxtjs/composition-api'

import AlertCircleIcon from '@/assets/img/alert-circle.svg'

export default defineComponent({
    components: {
        AlertCircleIcon
    }
})
