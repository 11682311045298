var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "app-checkbox"
  }, [_c('label', {
    class: {
      'app-checkbox__item': true,
      'app-checkbox__item--disabled': _vm.disabled
    }
  }, [_vm.reverse ? _c('span', {
    staticClass: "app-checkbox__item-text"
  }, [_vm._t("default", function () {
    return [_vm._v(_vm._s(_vm.text))];
  })], 2) : _vm._e(), _vm._v(" "), _c('input', {
    class: {
      'app-checkbox__core': true,
      'app-checkbox__core--error': _vm.errors.length
    },
    attrs: {
      "type": "checkbox",
      "disabled": _vm.disabled,
      "name": _vm.name
    },
    domProps: {
      "checked": _vm.value === _vm.valueOn
    },
    on: {
      "change": _vm.onChange,
      "input": _vm.onInput
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "app-checkbox__item-label"
  }), _vm._v(" "), !_vm.reverse ? _c('span', {
    staticClass: "app-checkbox__item-text"
  }, [_vm._t("default", function () {
    return [_vm._v(_vm._s(_vm.text))];
  })], 2) : _vm._e()]), _vm._v(" "), _vm.errors.length ? _c('div', {
    staticClass: "app-checkbox__errors"
  }, _vm._l(_vm.errors, function (error, index) {
    return _c('div', {
      key: index,
      staticClass: "app-checkbox__error",
      domProps: {
        "innerHTML": _vm._s(error)
      }
    });
  }), 0) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }