/* eslint-disable import/no-extraneous-dependencies */
import { Components as Partner } from '@/types/client.partner'
import { Context } from '@nuxt/types'
import { emailRegExp, phoneRegExp } from '@/composables/regexps'
import { reactive } from '@nuxtjs/composition-api'
import { SelectOption } from '@/interfaces/select-option.interface'
import { Merge } from '../joins'

export enum SignUpSteps {
    Registration = 'sign-up-step-registration',
    Approve = 'sign-up-step-approve',
    Password = 'sign-up-step-password',
    Finish = 'sign-up-step-finish'
}

export interface SignUpState {
    login: string
    signUpId: string
    step: SignUpSteps
    activityTypeId?: string
}

export interface SignUpConfig {
    allowedIdentifierTypes?: Partner.Schemas.UserIdentifierType[]
    buttonsColor?: 'blue' | 'green'
    requireActivityType?: boolean
    offerLink?: string
    personalDataPolicyLink?: string
}

export type SignUpStepCallbackResult<T = void> = Promise<T> | T

export interface SignUp<T = unknown> {
    readonly type: T
    state: SignUpState
    config?: SignUpConfig
    start?: () => SignUpStepCallbackResult
    approve?: (code: string) => SignUpStepCallbackResult
    setPassword?: (password: string) => SignUpStepCallbackResult
    finish?: () => SignUpStepCallbackResult
    login?: () => SignUpStepCallbackResult
    fetchActivityTypes?: () => SignUpStepCallbackResult<SelectOption[]>
}

export type CreateSignUp<T> = (context: unknown) => SignUp<T>

export class SignUpError extends Error {
    constructor (message: string) {
        super(message)

        Object.setPrototypeOf(this, SignUpError.prototype)
    }
}

export const getOriginUrl = () => {
    const origin = new URLSearchParams(window.location.search).get('origin')
    const regSource = new URLSearchParams(window.location.search).get('reg_source')

    if (!origin && !regSource) {
        return window.location.href
    }

    return origin || regSource
}

export const getReferrerId = () => {
    const referrerId = new URLSearchParams(window.location.search).get('referrerId')

    if (!referrerId) {
        return null
    }

    return referrerId
}

export const getDefaultState = (step: SignUpSteps = SignUpSteps.Registration): SignUpState => ({
    login: '',
    signUpId: '',
    step,
    activityTypeId: undefined
})

interface CreateOptions {
    state: SignUpState
    context: Context
}

type createSignUpCallback = (options: CreateOptions) => Merge<
    Omit<SignUp, 'type'>,
    { state?: SignUp['state'] }
>

const defaultConfig: SignUpConfig = {
    personalDataPolicyLink: 'https://www.workle.ru/resources/doc/personal-data-processing-policy.htm',
    buttonsColor: 'blue'
} as const

export const createSignUp = <T extends string>(
    type: T,
    callback: createSignUpCallback
): CreateSignUp<T> => (context: unknown) => {
        const options: CreateOptions = {
            state: reactive(getDefaultState()),
            context: context as Context
        }

        const creatorData = callback(options)

        return {
            state: options.state,
            ...creatorData,
            config: Object.assign(defaultConfig, creatorData.config),
            type
        }
    }

export const defineLoginType = (login: string): Partner.Schemas.UserIdentifierType => {
    if (emailRegExp.test(login)) {
        return 'Email'
    }

    if (phoneRegExp.test(login)) {
        return 'PhoneNumber'
    }

    return 'Unknown'
}
