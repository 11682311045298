var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('mounting-portal', {
    attrs: {
      "append": "",
      "slim": "",
      "mount-to": "body"
    }
  }, [_c('transition', {
    attrs: {
      "name": _vm.transitionName
    }
  }, [_vm.isOpen ? _c('div', {
    class: ['base-modal', _vm.name]
  }, [_c('div', {
    staticClass: "base-modal__background"
  }, [_vm._t("background", null, null, {
    isOpen: _vm.isOpen,
    open: _vm.open,
    close: _vm.close,
    unclosable: _vm.unclosable
  })], 2), _vm._v(" "), _c('div', {
    staticClass: "base-modal__content",
    style: _vm.placementStyle,
    on: {
      "click": function ($event) {
        if ($event.target !== $event.currentTarget) { return null; }
        return _vm.close(true);
      }
    }
  }, [_vm._t("content", null, null, {
    isOpen: _vm.isOpen,
    open: _vm.open,
    close: _vm.close,
    unclosable: _vm.unclosable
  })], 2)]) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }