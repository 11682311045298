












































import { RawLocation } from 'vue-router'

import {
    PropType,
    defineComponent,
    onMounted,
    useContext,
    ref
} from '@nuxtjs/composition-api'

import CatalogProfileSection from '@/components/customer/TheCatalog/-CatalogProfileSection.vue'
import CatalogProfile from '@/components/customer/TheCatalog/-CatalogProfile.vue'
import HeaderNotificationsButton from '@/components/pages/TheHeader/-HeaderNotificationsButton.vue'
import HeaderMessagesButton from '@/components/pages/TheHeader/-HeaderMessagesButton.vue'
import CatalogCabinetTypes from "@/components/customer/TheCatalog/-CatalogCabinetTypes.vue";
import MobileCatalogNavigation from "@/components/customer/TheCatalog/-MobileCatalogNavigation.vue";

import LogoutIcon from '@/assets/img/log-out.svg'

import { useDeviceWatcher } from '@/composables/device-watcher'
import { CatalogItem as CatalogItemInterface } from "@/interfaces/catalog.interface";
import { NavigationItem } from "@/interfaces/navigation.interface";
import { fetchUser, user } from "@/composables/user";
import { closeAside } from "@/composables/asides";

export default defineComponent({
    components: {
        MobileCatalogNavigation,
        CatalogCabinetTypes,
        CatalogProfileSection,
        CatalogProfile,
        HeaderNotificationsButton,
        HeaderMessagesButton,
        LogoutIcon
    },
    props: {
        profileLink: {
            type: [ String, Object ] as PropType<RawLocation | null>,
            default: null
        },
        catalogItems: {
            type: Array as PropType<CatalogItemInterface[]>,
            default: () => []
        },
        navigation: {
            type: Array as PropType<NavigationItem[]>,
            required: true
        }
    },
    setup () {
        const context = useContext()
        const { $auth, $apiCustomer } = context

        const channelId = ref()

        const {
            attach,
            currentDevice
        } = useDeviceWatcher([
            { alias: 'mobile', breakpoint: 0 },
            { alias: 'desktop', breakpoint: 1024 }
        ])

        const getChannelId = async () => {
            const { data } = await $apiCustomer.worklegramChannelsGetChannelByType(null, {
                userId: user.value.id,
                channelTypeAlias: 'LoyaltyProgram'
            })
            channelId.value = data.clickHouseChannel?.channelId
        }

        const logout = () => $auth.logout()

        onMounted(async () => {
            attach()

            if (currentDevice.value?.alias === 'mobile') {
                await fetchUser(context)
                getChannelId()
            }
        })

        return {
            currentDevice,
            channelId,
            logout,
            closeAside
        }
    }
})
