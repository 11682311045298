var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-modal', _vm._g(_vm._b({
    ref: "$modal",
    staticClass: "popup",
    attrs: {
      "name": "popup",
      "wide": "",
      "min-width": 200
    }
  }, 'app-modal', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "popup__body",
    on: {
      "click": function ($event) {
        return _vm.markEvent('Click');
      }
    }
  }, [_c('a', {
    staticClass: "popup__link",
    attrs: {
      "target": "_blank",
      "href": _vm.popup.redirectUrl
    }
  }, [_c('img', {
    staticClass: "popup__image",
    attrs: {
      "src": _vm.generalizeLinkImage(_vm.popup.imageKey)
    }
  })])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }