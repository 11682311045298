
















































import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
    props: {
        value: {
            type: [ Boolean, String, Number ],
            default: false
        },
        name: {
            type: String,
            required: true
        },
        text: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        errors: {
            type: [ Array, Object ],
            default: () => []
        },
        valueOn: {
            type: [ Boolean, String, Number ],
            default: true
        },
        valueOff: {
            type: [ Boolean, String, Number ],
            default: false
        },
        reverse: {
            type: Boolean,
            default: false
        }
    },
    setup (props, { emit }) {
        const onChange = (event: Event) => {
            emit('on-change', event)
        }

        const onInput = (event: Event) => {
            if (!event.target) return undefined

            const target = event.target as HTMLInputElement
            const value = target.checked ? props.valueOn : props.valueOff

            emit('on-change', event)
            emit('on-input', value)
            emit('input', value)
        }

        return {
            onChange,
            onInput
        }
    }
})
